import React from 'react';
import Navbar from '../../components/Navbar';
import RedemptionHouseFeature from '../../components/RedemptionHouseFeature';
import ContactCta from '../../components/ContactCta';
import Affiliates from '../../components/affiliates';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { Helmet } from "react-helmet";

function RedemptionPage() {
  return (
    <>
      <Helmet title="Redemption House" />
      <Navbar />
      <RedemptionHouseFeature />
      <ContactCta />
      <Affiliates />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default RedemptionPage;
