import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo/logo.png";
import "./style.css";

const Footer = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="tp-site-footer">
      <div className="tp-upper-footer">
        <div className="container footer-container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="footer-logo widget-title">
                  <Link onClick={ClickHandler} to="/home">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <div className="widget-title">
                  <h3>Endependence!</h3>
                <span className="lead text-white">
                  Guiding you to a new life
                </span>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/programs">
                      Programs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/community" title="">
                      Community
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget market-widget tp-service-link-widget">
                <div className="widget-title">
                  <h3>Contact</h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-pin"></i>Waterbury, CT
                    </li>
                    <li>
                      <i className="fi flaticon-call"></i>203-525-1989
                    </li>
                    <li>
                      <i className="fi flaticon-envelope"></i>
                      info@endependence.org
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tp-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">&copy; 2024 Endependence LLC.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
