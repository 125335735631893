/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createCurrentMedicalConditions } from "../graphql/mutations";
const client = generateClient();
export default function CurrentMedicalConditionsCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    heartDisease: false,
    diabetes: false,
    cancer: false,
    seizures: false,
    tuberculosis: false,
    activityRestrictions: false,
    dietaryRestrictions: false,
    allergies: "",
    infectiousDisease: false,
    otherConditions: "",
  };
  const [heartDisease, setHeartDisease] = React.useState(
    initialValues.heartDisease
  );
  const [diabetes, setDiabetes] = React.useState(initialValues.diabetes);
  const [cancer, setCancer] = React.useState(initialValues.cancer);
  const [seizures, setSeizures] = React.useState(initialValues.seizures);
  const [tuberculosis, setTuberculosis] = React.useState(
    initialValues.tuberculosis
  );
  const [activityRestrictions, setActivityRestrictions] = React.useState(
    initialValues.activityRestrictions
  );
  const [dietaryRestrictions, setDietaryRestrictions] = React.useState(
    initialValues.dietaryRestrictions
  );
  const [allergies, setAllergies] = React.useState(initialValues.allergies);
  const [infectiousDisease, setInfectiousDisease] = React.useState(
    initialValues.infectiousDisease
  );
  const [otherConditions, setOtherConditions] = React.useState(
    initialValues.otherConditions
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setHeartDisease(initialValues.heartDisease);
    setDiabetes(initialValues.diabetes);
    setCancer(initialValues.cancer);
    setSeizures(initialValues.seizures);
    setTuberculosis(initialValues.tuberculosis);
    setActivityRestrictions(initialValues.activityRestrictions);
    setDietaryRestrictions(initialValues.dietaryRestrictions);
    setAllergies(initialValues.allergies);
    setInfectiousDisease(initialValues.infectiousDisease);
    setOtherConditions(initialValues.otherConditions);
    setErrors({});
  };
  const validations = {
    heartDisease: [],
    diabetes: [],
    cancer: [],
    seizures: [],
    tuberculosis: [],
    activityRestrictions: [],
    dietaryRestrictions: [],
    allergies: [],
    infectiousDisease: [],
    otherConditions: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          heartDisease,
          diabetes,
          cancer,
          seizures,
          tuberculosis,
          activityRestrictions,
          dietaryRestrictions,
          allergies,
          infectiousDisease,
          otherConditions,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createCurrentMedicalConditions.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "CurrentMedicalConditionsCreateForm")}
      {...rest}
    >
      <SwitchField
        label="Heart disease"
        defaultChecked={false}
        isDisabled={false}
        isChecked={heartDisease}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease: value,
              diabetes,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.heartDisease ?? value;
          }
          if (errors.heartDisease?.hasError) {
            runValidationTasks("heartDisease", value);
          }
          setHeartDisease(value);
        }}
        onBlur={() => runValidationTasks("heartDisease", heartDisease)}
        errorMessage={errors.heartDisease?.errorMessage}
        hasError={errors.heartDisease?.hasError}
        {...getOverrideProps(overrides, "heartDisease")}
      ></SwitchField>
      <SwitchField
        label="Diabetes"
        defaultChecked={false}
        isDisabled={false}
        isChecked={diabetes}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes: value,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.diabetes ?? value;
          }
          if (errors.diabetes?.hasError) {
            runValidationTasks("diabetes", value);
          }
          setDiabetes(value);
        }}
        onBlur={() => runValidationTasks("diabetes", diabetes)}
        errorMessage={errors.diabetes?.errorMessage}
        hasError={errors.diabetes?.hasError}
        {...getOverrideProps(overrides, "diabetes")}
      ></SwitchField>
      <SwitchField
        label="Cancer"
        defaultChecked={false}
        isDisabled={false}
        isChecked={cancer}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer: value,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.cancer ?? value;
          }
          if (errors.cancer?.hasError) {
            runValidationTasks("cancer", value);
          }
          setCancer(value);
        }}
        onBlur={() => runValidationTasks("cancer", cancer)}
        errorMessage={errors.cancer?.errorMessage}
        hasError={errors.cancer?.hasError}
        {...getOverrideProps(overrides, "cancer")}
      ></SwitchField>
      <SwitchField
        label="Seizures"
        defaultChecked={false}
        isDisabled={false}
        isChecked={seizures}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures: value,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.seizures ?? value;
          }
          if (errors.seizures?.hasError) {
            runValidationTasks("seizures", value);
          }
          setSeizures(value);
        }}
        onBlur={() => runValidationTasks("seizures", seizures)}
        errorMessage={errors.seizures?.errorMessage}
        hasError={errors.seizures?.hasError}
        {...getOverrideProps(overrides, "seizures")}
      ></SwitchField>
      <SwitchField
        label="Tuberculosis"
        defaultChecked={false}
        isDisabled={false}
        isChecked={tuberculosis}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures,
              tuberculosis: value,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.tuberculosis ?? value;
          }
          if (errors.tuberculosis?.hasError) {
            runValidationTasks("tuberculosis", value);
          }
          setTuberculosis(value);
        }}
        onBlur={() => runValidationTasks("tuberculosis", tuberculosis)}
        errorMessage={errors.tuberculosis?.errorMessage}
        hasError={errors.tuberculosis?.hasError}
        {...getOverrideProps(overrides, "tuberculosis")}
      ></SwitchField>
      <SwitchField
        label="Activity restrictions"
        defaultChecked={false}
        isDisabled={false}
        isChecked={activityRestrictions}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions: value,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.activityRestrictions ?? value;
          }
          if (errors.activityRestrictions?.hasError) {
            runValidationTasks("activityRestrictions", value);
          }
          setActivityRestrictions(value);
        }}
        onBlur={() =>
          runValidationTasks("activityRestrictions", activityRestrictions)
        }
        errorMessage={errors.activityRestrictions?.errorMessage}
        hasError={errors.activityRestrictions?.hasError}
        {...getOverrideProps(overrides, "activityRestrictions")}
      ></SwitchField>
      <SwitchField
        label="Dietary restrictions"
        defaultChecked={false}
        isDisabled={false}
        isChecked={dietaryRestrictions}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions: value,
              allergies,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.dietaryRestrictions ?? value;
          }
          if (errors.dietaryRestrictions?.hasError) {
            runValidationTasks("dietaryRestrictions", value);
          }
          setDietaryRestrictions(value);
        }}
        onBlur={() =>
          runValidationTasks("dietaryRestrictions", dietaryRestrictions)
        }
        errorMessage={errors.dietaryRestrictions?.errorMessage}
        hasError={errors.dietaryRestrictions?.hasError}
        {...getOverrideProps(overrides, "dietaryRestrictions")}
      ></SwitchField>
      <TextField
        label="Allergies"
        isRequired={false}
        isReadOnly={false}
        value={allergies}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies: value,
              infectiousDisease,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.allergies ?? value;
          }
          if (errors.allergies?.hasError) {
            runValidationTasks("allergies", value);
          }
          setAllergies(value);
        }}
        onBlur={() => runValidationTasks("allergies", allergies)}
        errorMessage={errors.allergies?.errorMessage}
        hasError={errors.allergies?.hasError}
        {...getOverrideProps(overrides, "allergies")}
      ></TextField>
      <SwitchField
        label="Infectious disease"
        defaultChecked={false}
        isDisabled={false}
        isChecked={infectiousDisease}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease: value,
              otherConditions,
            };
            const result = onChange(modelFields);
            value = result?.infectiousDisease ?? value;
          }
          if (errors.infectiousDisease?.hasError) {
            runValidationTasks("infectiousDisease", value);
          }
          setInfectiousDisease(value);
        }}
        onBlur={() =>
          runValidationTasks("infectiousDisease", infectiousDisease)
        }
        errorMessage={errors.infectiousDisease?.errorMessage}
        hasError={errors.infectiousDisease?.hasError}
        {...getOverrideProps(overrides, "infectiousDisease")}
      ></SwitchField>
      <TextField
        label="Other conditions"
        isRequired={false}
        isReadOnly={false}
        value={otherConditions}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              heartDisease,
              diabetes,
              cancer,
              seizures,
              tuberculosis,
              activityRestrictions,
              dietaryRestrictions,
              allergies,
              infectiousDisease,
              otherConditions: value,
            };
            const result = onChange(modelFields);
            value = result?.otherConditions ?? value;
          }
          if (errors.otherConditions?.hasError) {
            runValidationTasks("otherConditions", value);
          }
          setOtherConditions(value);
        }}
        onBlur={() => runValidationTasks("otherConditions", otherConditions)}
        errorMessage={errors.otherConditions?.errorMessage}
        hasError={errors.otherConditions?.hasError}
        {...getOverrideProps(overrides, "otherConditions")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
