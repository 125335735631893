import React from "react";
import Logo from "../../images/logo/logo.png";
import { NavLink } from "react-router-dom";
import "./style.scss";

const LogoImage = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="logo">
      <NavLink onClick={ClickHandler} to="/home" title="">
        <img src={Logo} alt="" />
        <span className="logoText d-flex">
          <span className="logoName">Endependence</span>
          <span className="logoBreak"></span>
          <span className="logoSlogan">Addiction Services</span>
        </span>
      </NavLink>
    </div>
  );
};

export default LogoImage;
