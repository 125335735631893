import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import AboutContent from '../../components/AboutContent';
import { Helmet } from "react-helmet";

function AboutPage() {
  return (
    <>
      <Helmet title="About" />
      <Navbar />
      <AboutContent />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default AboutPage;
