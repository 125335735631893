import React from 'react'
import {Link} from 'react-router-dom'
import abimg from '../../images/about/hands.jpg'
import abimg2 from '../../images/about/1.png'
import abimg3 from '../../images/about/2.png'
import abimg4 from '../../images/shape/shape2.png'
import './style.css'

const AboutNewBeginning = () => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12  grid col-12">
                        <div className="about-cercle">
                            <div className="about-img">
                                <img src={abimg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1 grid  col-md-12 col-12">
                        <div className="about-text">
                            <div className="section-title">
                                <div className="thumb-text">
                                    <span>WELCOME!</span>
                                </div>
                                <h2>We <span>help</span> addicts rebuild their <span>lives</span>.</h2>
                            </div>
                            <p>We connect addicts and their families with the essential resources needed to break free from the chains of addiction.</p>
                            <Link onClick={ClickHandler} to="/programs" className="theme-btn">Our Recovery Program<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                            <div className="ab-icon-area">
                                <div className="about-icon-wrap">
                                    <div className="about-icon-item">
                                        <div className="ab-icon">
                                            <img draggable="false" src={abimg2} alt="" />
                                        </div>
                                        <div className="ab-text">
                                            <h2><Link onClick={ClickHandler} to="/case-single">Recovery.</Link></h2>
                                        </div>
                                    </div>
                                    <div className="about-icon-item">
                                        <div className="ab-icon ab-icon2">
                                            <img draggable="false" src={abimg3} alt="" />
                                        </div>
                                        <div className="ab-text">
                                            <h2><Link onClick={ClickHandler} to="/case-single">Support.</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ab-shape-3">
                        <img src={abimg4} alt="" />
                    </div>
        </div>
    )
}

export default AboutNewBeginning;