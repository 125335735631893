import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-2.jpg'
import hero3 from '../../images/slider/slide-3.jpg'
import hero4 from '../../images/shape/shape.png'

import './style.scss'

class Hero extends Component {
    render() {

        var settings = {
            dots: true,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="hero-slider hero-style-1">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero1})`}}>
                            <div className="container">
                                <div className="slide-thumb">
                                    <span>WELCOME!</span>
                                </div>
                                <div className="slide-title">
                                    <h2>We Help Addicts Recover.</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>We connect individuals and their families to treatment and recovery resources.</p>
                                </div>
                                <div  className="slide-btns">
                                    <Link to="/about" className="theme-btn">Start Your Journey<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                            <div className="slide-shape">
                                <img src={hero4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero2})`}}>
                            <div className="container">
                                <div className="slide-thumb">
                                    <span>WELCOME!</span>
                                </div>
                                <div className="slide-title">
                                    <h2>Treatment For Everyone.</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>Therapy, detox, rehabilitation and after-care plans, custom tailored to each individual.</p>
                                </div>
                                <div  className="slide-btns">
                                    <Link to="/about" className="theme-btn">Start Your Journey<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                            <div className="slide-shape">
                               <img src={hero4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero3})`}}>
                            <div className="container">
                                <div className="slide-thumb">
                                    <span>WELCOME!</span>
                                </div>
                                <div className="slide-title">
                                    <h2>Start Recovering Now.</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>This is where your journey begins. Take the first step in making a better life for yourself.</p>
                                </div>
                                <div  className="slide-btns">
                                    <Link to="/about" className="theme-btn">Start Your Journey<i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="slide-shape">
                               <img src={hero4} alt="" />
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        )
    }
}

export default Hero;