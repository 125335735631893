import React from 'react'
// import ReactDOM from "react-dom";

import abimg4 from "../../images/shape/shape2.png";
import MissionIcons from '../mission';

import { Alert, Stack, Container } from '@mui/material';

import './style.css'

// import { generateClient } from 'aws-amplify/api';

import {
    ClientIntakeCreateForm 
   } from '../../ui-components/'

// const client = generateClient();

class IntakeForm extends React.Component {
    constructor(props) {
        super(props);
        // this.client = generateClient();
        this.state = {
            name: '',
            phone: '',
            email: '',
            message: '',
            error: {},
            submitted: false
        };

        // this.setContactFields = this.setContactFields.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.submitData = this.submitData.bind(this);


        console.log(this.client)
    }

    // setContactFields(form) {
    //     // this.state = form
    //     // this.setState({})
    // }

    handleFormChange = (event) => {
        let { name: fieldName, value } = event.target;

        this.setState({
            [fieldName]: value
        });
    };

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    };

    componentDidMount() {
        this.setState({
            name: '',
            phone: '',
            email: '',
            message: '',
            error: {},
            submitted: false
        });
    }

    // async submitData(fields) {
    async submitData() {
        // const newClientIntake = await client.graphql({
        //     query: createClientIntake,
        //     variables: {
        //         input: {
        //         "firstName": "Lorem ipsum dolor sit amet",
        //         "middleInitial": "Lorem ipsum dolor sit amet",
        //         "lastName": "Lorem ipsum dolor sit amet",
        //         "preferredName": "Lorem ipsum dolor sit amet",
        //         "dob": "1970-01-01Z",
        //         "age": 1020,
        //         "socialSecurity": "Lorem ipsum dolor sit amet",
        //         "personCompletingForm": "Lorem ipsum dolor sit amet",
        //         "personCompletingFormRelationship": "Lorem ipsum dolor sit amet",
        //         "addressStreet": "Lorem ipsum dolor sit amet",
        //         "addressCity": "Lorem ipsum dolor sit amet",
        //         "addressState": "Lorem ipsum dolor sit amet",
        //         "addressZip": "Lorem ipsum dolor sit amet",
        //         "addressLivedAt": 1020,
        //         "cellPhone": "(555) 123-6789",
        //         "email": "test12346789@testemailtestemail.com",
        //         "homePhone": "(555) 123-6789",
        //         "maritialStatus": "Lorem ipsum dolor sit amet",
        //         "race": "Lorem ipsum dolor sit amet",
        //         "highestEduction": "Lorem ipsum dolor sit amet",
        //         "isVeteran": true,
        //         "isActiveDuty": true,
        //         "militaryBranch": "Lorem ipsum dolor sit amet",
        //         "militaryDeployed": true,
        //         "arrested": true,
        //         "arrestedCharge": "Lorem ipsum dolor sit amet",
        //         "convicted": true,
        //         "convictedCharges": "Lorem ipsum dolor sit amet",
        //         "currentLegalConcerns": "Lorem ipsum dolor sit amet",
        //         "educationalExperience": "Lorem ipsum dolor sit amet",
        //         "employmentLevel": "Lorem ipsum dolor sit amet",
        //         "employmentLength": "Lorem ipsum dolor sit amet",
        //         "employmentConcerns": "Lorem ipsum dolor sit amet",
        //         "financialConcerns": "Lorem ipsum dolor sit amet",
        //         "susbstanceAlcohol": true,
        //         "substanceAlcoholFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceMarijuanna": true,
        //         "substanceMarijuannaFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceCocaine": "Lorem ipsum dolor sit amet",
        //         "substanceCocaineFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceMeth": "Lorem ipsum dolor sit amet",
        //         "substanceMethFrequency": "Lorem ipsum dolor sit amet",
        //         "substancePainPills": "Lorem ipsum dolor sit amet",
        //         "substancePainPillsFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceHeroin": "Lorem ipsum dolor sit amet",
        //         "substanceHeroinFrequeny": "Lorem ipsum dolor sit amet",
        //         "substanceSleepingPills": "Lorem ipsum dolor sit amet",
        //         "substanceSleepingPillsFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceTranquillizers": "Lorem ipsum dolor sit amet",
        //         "substanceTranquillizersFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceNicotine": "Lorem ipsum dolor sit amet",
        //         "substanceNicotineFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceCaffeine": "Lorem ipsum dolor sit amet",
        //         "substanceCaffeineFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceDietPills": "Lorem ipsum dolor sit amet",
        //         "substanceDietPillsFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceSpice": "Lorem ipsum dolor sit amet",
        //         "substanceSpiceFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceBathSalts": "Lorem ipsum dolor sit amet",
        //         "substanceBathSaltsFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceLsdPcp": "Lorem ipsum dolor sit amet",
        //         "substanceLsdPcpFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceEcstasy": "Lorem ipsum dolor sit amet",
        //         "substanceEcstasayFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceOther": "Lorem ipsum dolor sit amet",
        //         "substanceOtherFrequency": "Lorem ipsum dolor sit amet",
        //         "substanceAlcoholCurrent": true,
        //         "substanceMarijuannaCurrent": "Lorem ipsum dolor sit amet",
        //         "substanceCocaineCurrent": true,
        //         "substanceMethCurrent": true,
        //         "substancePainPillsCurrent": true,
        //         "substanceTranquillizersCurren": true,
        //         "substanceNicotineCurrent": true,
        //         "substanceCaffeineCurrent": true,
        //         "substanceDietPillsCurrent": true,
        //         "substanceSpiceCurrent": true,
        //         "substanceBathSaltsCurrent": true,
        //         "substanceLsdPcpCurrent": true,
        //         "substanceEctasyCurrent": true,
        //         "otherPeopleSaidProblem": true,
        //         "substanceInterfered": true,
        //         "substanceArrested": true,
        //         "substanceCutBack": true,
        //         "substanceWithdrawal": true,
        //         "substancePreoccupied": true,
        //         "substanceFocused": true,
        //         "substanceActivities": true,
        //         "previousTreatment": "Lorem ipsum dolor sit amet",
        //         "insuranceProvider": "Lorem ipsum dolor sit amet",
        //         "insurancePolicy": "Lorem ipsum dolor sit amet",
        //         "insuranceGroup": "Lorem ipsum dolor sit amet",
        //         "insuranceExpiration": "Lorem ipsum dolor sit amet"
        //     }
        //     }
        // });

    }

    submitHandler(e) {
        e.preventDefault();

        const { name, email, phone, message, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (phone === '') {
            error.phone = "Please enter your phone";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }

        if (message === '') {
            error.message = "Please enter a message";
        }

        if (error && Object.keys(error).length > 0) {
            this.setState({
                error
            })
        } else {
            this.setState({
                name,
                email,
                phone,
                message,
                error: {},
                submitted: true
            });
            this.submitData();
        }
    }

    // <Container>
    //     <Stack sx={{ width: '100%', my: 1 }} spacing={2}>
    //         <div className="error-message ">
    //             {Object.values(errors).map((message, index) => (
    //                 <Alert key={index} severity="error" sx={{ my: 1 }}>{message}</Alert>
    //             ))}
    //         </div>
    //     </Stack>
    // </Container>
    // );

    printErrors = ({ errors }) => {
        return (
            <Container>
                <Stack sx={{ width: '100%', my: 1 }} spacing={2}>
                    {Object.entries(errors).map(([, value], index) => (
                        <div key={index} className="error-message">
                            <Alert key={index} severity="error" sx={{ my: 1 }}>{value}</Alert>
                        </div>
                    ))}
                </Stack>
            </Container>
        );
    }

    renderHeader(){
        return (
            <>
                <div className="tp-case-details-area"> 
                    <div className="container"> 
                        <div className="row justify-content-center my-4"> 
                            <div className="col col-lg-8"> 
                                <div className="tp-case-details-wrap"> 
                                    <div className="tp-case-details-text"> 
                                        <div className="tp-blog-single-section wrap-even"> 
                                            <div className="comment-respond"> 
                                                <h1>Congratulations!</h1> 
                                                <h4>You are on your way to a better life!</h4> 
                                                {/* <Alert severity='success'>Contact request submitted</Alert>  */}
                                            </div> 
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </>
        )
    }

    renderForm() {
        // const {
        //     name,
        //     email,
        //     phone,
        //     message,
        //     error
        // } = this.state;

        return (
            <>
            <this.renderHeader />
                <div className="tp-case-details-area mb-1">
                    <div className="container">
                        <div className="row justify-content-center my-4">
                            <div className="col col-lg-8">
                            <ClientIntakeCreateForm
                                onSubmit={(fields) => {
                                    console.log(fields)
                                    this.submitData(fields)
                                    // // Example function to trim all string inputs
                                    // const updatedFields = {}
                                    // Object.keys(fields).forEach(key => {
                                    //     if (typeof fields[key] === 'string') {
                                    //         updatedFields[key] = fields[key].trim()
                                    //     } else {
                                    //         updatedFields[key] = fields[key]
                                    //     }
                                    // })
                                    // return updatedFields
                                }}
                            />
                            </div>
                        </div>
                        <MissionIcons />
                        <div className="ab-shape">
                            <img src={abimg4} alt="" />
                        </div>
                    </div>
                    <div className="ab-shape">
                        <img src={abimg4} alt="" />
                    </div>
                </div>
                <div className="ab-shape-2">
                    <img src={abimg4} alt="" />
                </div>

            </>
        );
    }

    render() {
        // const { error, submitted } = this.state;
        const { submitted } = this.state;
        if (submitted == true) {
            return (
                <div className="tp-case-details-area my-1">
                    <div className="container">
                        <div className="row justify-content-center my-4">
                            <div className="col col-lg-8">
                                <div className="tp-case-details-wrap">
                                    <div className="tp-case-details-text">
                                        <div className="tp-blog-single-section wrap-even">
                                            <div className="comment-respond">
                                                <h1 className="">Thank you for your submission!</h1>
                                                <h4>We will contact you with treatment options!</h4>
                                                <Alert severity='success'>Contact request submitted</Alert>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    {this.renderForm()}
                </>
            );
        }
    }
}
export default IntakeForm;