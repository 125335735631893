import React from 'react'
import './style.css'

const ContactCta = () => {

    return(
        <div className="contact-cta-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12 col-12">
                        <div className="contact-cta-left">
                            <h2>
                            <span className="contact-cta-green">If You Are Ready To Change Your Life.</span> <span className="contact-cta-orange">Call Us Today!</span></h2>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12">
                        <div className="contact-cta-wrap">
                            <div className="contact-cta-call">
                                <h3>203-430-7793</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCta;