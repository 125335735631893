import React from 'react';
import Navbar from '../../components/Navbar';
import Hero from '../../components/hero';
import AboutNewBeginning from '../../components/AboutNewBeginning';
import Features from '../../components/Features';
import CtaSection from '../../components/cta';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import AboutContent from '../../components/AboutContent';
import { Helmet } from "react-helmet";
import ContactForm from "../../components/ContactForm";

function HomePage() {
  return (
    <>
      <Helmet title="Home" />
      <Navbar />
      <Hero />
      <AboutNewBeginning />
      <Features />
      {/* <Affiliates /> */}
      <AboutContent />
      <CtaSection />
      <ContactForm />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default HomePage;
