import React from 'react'
// import ReactDOM from "react-dom";

import abimg4 from "../../images/shape/shape2.png";
import MissionIcons from '../mission';

import { Alert, Stack, Container } from '@mui/material';

import './style.css'


import { generateClient } from 'aws-amplify/api';
import { createContactRequest } from '../../graphql/mutations';

const client = generateClient();

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        // this.client = generateClient();
        this.state = {
            name: '',
            phone: '',
            email: '',
            message: '',
            error: {},
            submitted: false
        };

        // this.setContactFields = this.setContactFields.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.submitData = this.submitData.bind(this);


        console.log(this.client)
    }

    // setContactFields(form) {
    //     // this.state = form
    //     // this.setState({})
    // }

    handleFormChange = (event) => {
        let { name: fieldName, value } = event.target;

        this.setState({
            [fieldName]: value
        });
    };

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    };

    componentDidMount() {
        this.setState({
            name: '',
            phone: '',
            email: '',
            message: '',
            error: {},
            submitted: false
        });
    }

    async submitData() {
        console.log("Attempting to submit data");
        // try {
        //     const newContactRequest = await this.client.graphql({
        //         query: createContactRequest,
        //         variables: {
        //             input: {
        //                 name: this.state.name,
        //                 phone: this.state.phone,
        //                 email: this.state.email,
        //                 message: this.state.message,
        //             }
        //         }
        //     });
        // } catch (error) {
        //     console.log('There was an error')
        //     console.log(error);
        // }

        try {
            const createContactDetails = {
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                message: this.state.message
            }
            
            await client.graphql({
                query: createContactRequest,
                variables: {
                    input: createContactDetails
                }
        })
        } catch (error) {
            console.log('There was an error')
            console.log(error);
        }

    }

    submitHandler(e) {
        e.preventDefault();

        const { name, email, phone, message, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (phone === '') {
            error.phone = "Please enter your phone";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }

        if (message === '') {
            error.message = "Please enter a message";
        }

        if (error && Object.keys(error).length > 0) {
            this.setState({
                error
            })
        } else {
            this.setState({
                name,
                email,
                phone,
                message,
                error: {},
                submitted: true
            });
            this.submitData();
        }
    }

    // <Container>
    //     <Stack sx={{ width: '100%', my: 1 }} spacing={2}>
    //         <div className="error-message ">
    //             {Object.values(errors).map((message, index) => (
    //                 <Alert key={index} severity="error" sx={{ my: 1 }}>{message}</Alert>
    //             ))}
    //         </div>
    //     </Stack>
    // </Container>
    // );

    printErrors = ({ errors }) => {
        return (
            <Container>
                <Stack sx={{ width: '100%', my: 1 }} spacing={2}>
                    {Object.entries(errors).map(([, value], index) => (
                        <div key={index} className="error-message">
                            <Alert key={index} severity="error" sx={{ my: 1 }}>{value}</Alert>
                        </div>
                    ))}
                </Stack>
            </Container>
        );
    }

    renderForm() {
        // const {
        //     name,
        //     email,
        //     phone,
        //     message,
        //     error
        // } = this.state;

        return (
            <>
                <div className="tp-case-details-area my-1">
                    <div className="container">
                        <div className="row justify-content-center my-4">
                            <div className="col col-lg-8">
                                <div className="tp-case-details-wrap">
                                    <div className="tp-case-details-text">
                                        <div className="tp-blog-single-section wrap-even">
                                            <div className="comment-respond">
                                                <h1 className="">Get Support Now!</h1>
                                                <h4>We will contact you with treatment options!</h4>

                                                {/* <this.printErrors errors={error} /> */}

                                                <form onSubmit={this.submitHandler} onChange={this.handleFormChange} className="comment-form">
                                                    {/* <form onSubmit={this.submitHandler} className="comment-form"> */}

                                                    <div className="form-inputs">
                                                        <input name="name" placeholder="Name" type="text" /*onChange={this.changeHandler}*/ />
                                                        <input name="phone" placeholder="Phone Number" type="tel" />
                                                        <input name="email" placeholder="Email" type="email" />
                                                        <div className="form-textarea">
                                                            <textarea name="message" id="message" placeholder="Tell us about how we can help..." ></textarea>
                                                        </div>
                                                        <input type="submit" />
                                                        {/* <input value={first_name} onChange={this.changeHandler} type="text" name="first_name" placeholder="First Name" /> */}
                                                        {/* <p>{error.first_name ? error.first_name : ''}</p>
                                                        <input value={last_name} onChange={this.changeHandler} type="text" name="last_name" placeholder="Last Name" />
                                                        <p>{error.last_name ? error.last_name : ''}</p>
                                                        <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email" />
                                                        <p>{error.email ? error.email : ''}</p>
                                                        <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email" />
                                                        <p>{error.email ? error.email : ''}</p> */}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MissionIcons />
                        <div className="ab-shape">
                            <img src={abimg4} alt="" />
                        </div>
                    </div>
                    <div className="ab-shape">
                        <img src={abimg4} alt="" />
                    </div>
                </div>
                <div className="ab-shape-2">
                    <img src={abimg4} alt="" />
                </div>

            </>
        );
    }

    render() {
        // const { error, submitted } = this.state;
        const { submitted } = this.state;
        if (submitted == true) {
            return (
                <div className="tp-case-details-area my-1">
                    <div className="container">
                        <div className="row justify-content-center my-4">
                            <div className="col col-lg-8">
                                <div className="tp-case-details-wrap">
                                    <div className="tp-case-details-text">
                                        <div className="tp-blog-single-section wrap-even">
                                            <div className="comment-respond">
                                                <h1 className="">Thank you for your submission!</h1>
                                                <h4>We will contact you with treatment options!</h4>
                                                <Alert severity='success'>Contact request submitted</Alert>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    {this.renderForm()}
                </>
            );
        }
    }
}
export default ContactForm;