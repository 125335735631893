import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './views/App/App';
import * as serviceWorker from './serviceWorker';
import './css/style.css';
import './css/font-awesome.min.css';
import './css/themify-icons.css';
import './css/flaticon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-alice-carousel/lib/alice-carousel.css';

import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

ReactDOM.render(
    <App />,
    document.getElementById('root')
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
