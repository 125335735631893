import React from "react";
import "./style.css";

import jobPlacement from "../../images/vector/job-placement.png";
import soberHouse from "../../images/vector/sober-house.png";
import community from "../../images/vector/community.png";
import outpatient from "../../images/vector/outpatient.png";

const Programs = () => {
  return (
    <>
      <div className="section-container programs-container">

      <div className="row mt-5 d-md-flex-row">
          <div className="col col-12 col-md-6 order-0">
            <img src={jobPlacement} className="program-img" width="200" alt="" />
          </div>
          <div className="col col-12 col-md-6 order-1">
            <h4>Discover</h4>
            <h4 className="subheading">A better life</h4>
            <p className="text-muted">
Are you or someone you care about struggling with addiction? Are you tired of the endless cycle of despair, and looking for a way out?.</p>
          </div>
        </div>

        <div className="row mt-5 d-md-flex-row">
        <div className="col col-12 col-md-6 order-sm-0 order-md-1">
            <img src={soberHouse} className="program-img" alt="" />
          </div>

          <div className="col col-12 col-md-6 order-sm-1 order-md-0">
            <h4>Resources</h4>
            <h4 className="subheading">We&apos;re here to help!</h4>
            <p className="text-muted">
            Endependence organization is here to help. We connect addicts with the essential resources needed to break free from the chains of addiction and pave the way to a brighter, healthier future
            </p>
          </div>
        </div>


        <div className="row mt-5 d-md-flex-row">
        <div className="col col-12 col-md-6 order-0">
            <img src={community} className="program-img" width="200" alt="" />
          </div>
          <div className="col col-12 col-md-6 order-1">
            <h4>Community.</h4>
            <h4 className="subheading">
              Build your sober network!
            </h4>
            <p className="text-muted">
            Expert guidance and support from trained peers and professionals. Tailored treatment programs that cater to your unique needs.<br />
Join a community of individuals who understand what you&apos;re going through;<br />

The knowledge and tools to rebuild your life            
            </p>
          </div>
        </div>

        <div className="row mt-5 d-md-flex-row">
        <div className="col col-12 col-md-6 order-sm-0 order-md-1">
            <img src={outpatient} className="program-img" width="200" alt="" />
          </div>
          <div className="col col-12 col-md-6 order-sm-1 order-md-0">
            <h4>Take Back Control!</h4>
            <h4 className="subheading">
              With our tools, you can build a new life.
            </h4>
              <p className="text-muted">
              Imagine a life where addiction no longer has control over you. Picture waking up in the morning with a sense of purpose and hope, rather than dread and cravings. Envision mending broken relationships, regaining the trust of your loved ones, and finding a renewed sense of self-worth.<br /><br />

With our help, you can take the first step towards a life free from the chains of addiction. You can regain your health, your happiness, and your future.
              </p>
          </div>
        </div>

        <hr />
        
        <div className="row mt-5">
          <div className="col-md-6">
            <h4>Medication</h4>
            <h4 className="subheading">
              Licensed pharmacist consultation.
            </h4>
            <p className="text-muted">
              Review your medications, discuss any adverse side-effects, and continue to receive the medications you need to stay healthy. We can work with your provider to ensure you are receiving the best possible care.
            </p>
          </div>
          <div className="col-md-6">
            <h4>Scholarships Available</h4>
            <h4 className="subheading">
              Don&apos;t miss the opportunity to change your life!
            </h4>
            <p className="text-muted">
              We offer scholarships on a first-come first-serve basis. We do our
              best to try and help anyone who reaches out to us for help, but
              beds are limited. Call us to see if you qualify for a
              scholarship!.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
