import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const HeaderTopbar = () => {
    return(	
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-12">
                        <div className="row d-flex innerContainer align-items-lg-baseline">
                            <div className="p-2 col col-5 col-sm-4 col-md-2 phone-1 justify-content-center"><i className="fi flaticon-call topbar-icon"></i>+1 (860) 253-2892</div>
                            {/* <div className="w-100 d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none"></div> */}
                            <div className="p-2 col col-5 col-sm-4 col-md-2 phone-2 justify-content-center"><i className="fi flaticon-call"></i>+1 (914) 374-9227</div>
                            <div className="p-2 col col-12 col-md-4"><i className="fi flaticon-envelope"></i>info@endependence.net</div>

                            {/* <div className="col col-2 d-none d-md-block ml-auto">
                                <Link className="theme-btn donate.btn" to="/contact">
                                   Donate!
                                </Link>
                            </div> */}

                            <div className="col col-2 d-none d-md-block ml-auto">
                                <Link className="theme-btn donate.btn" to="/contact">
                                   Get Help Now!
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;