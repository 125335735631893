import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { Helmet } from "react-helmet";
import ContactForm from '../../components/ContactForm';
function ContactPage() {
  return (
    <>
      <Helmet title="Contact" />
      <Navbar />
      <ContactForm />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default ContactPage;
