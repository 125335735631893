import React from 'react'
import {Link} from 'react-router-dom'
import abimg from '../../images/houses/mansion.jpg'
import abimg4 from '../../images/shape/shape2.png'
import './style.css'

const RedemptionHouseFeature = () => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12  grid col-12">
                        <div className="about-cercle">
                            <div className="about-img">
                                <img src={abimg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1 grid  col-md-12 col-12">
                        <div className="about-text">
                            <div className="section-title">
                                <div className="thumb-text">
                                    <span>Coming Soon!</span>
                                </div>
                                <h2>The <span>New Redemption</span> <span>Recovery Center.</span></h2>
                            </div>
                            <p>Respit beds are available to those who relapse. The New Redemption Recovery Center offers a safe place for addicts who relapse.</p>
                            <Link onClick={ClickHandler} to="/contact" className="theme-btn">Get Help Now!<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ab-shape">
                 <img src={abimg4} alt="" />
            </div>
        </div>
    )
}

export default RedemptionHouseFeature;