import React from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Error from '../../components/404';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

function ErrorPage() {
  return (
    <>
      <Navbar />
      <PageTitle pageTitle="404" pagesub="404" />
      <Error />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default ErrorPage;
