import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Homepage from '../HomePage';
import AboutPage from '../AboutPage';
import ErrorPage from '../ErrorPage';
import ContactPage from '../ContactPage';
import IntakePage from '../IntakePage';
import ProgramsPage from '../ProgramsPage';
import RedemptionPage from '../RedemptionPage';
import DonatePage from '../DonatePage';

function AllRoute() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/home" component={Homepage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/programs" component={ProgramsPage} />
          <Route path="/intake" component={IntakePage} />
          <Route path="/404" component={ErrorPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/redemption" component={RedemptionPage} />
          <Route path="/donate" component={DonatePage} />
          <Route path="/intake" component={IntakePage} />
        </Switch>
      </Router>
    </div>
  );
}

export default AllRoute;
