import React from "react";
import abimg4 from "../../images/shape/shape2.png";

import Amenities from "../../components/amenities";

import "./style.css";

function AboutContent() {
  return (
    <>
      <div className="container my-3">
        {/* <div className="about-wrapper">
          <AboutFeature />

        </div> */}

        <div className="my-4 about-centered">
          <h4>
            Treatment that fits your <strong>goals.</strong>
          </h4>
        </div>
        <Amenities />

        <div className="about-wrapper about-centered">
          <h4 className="mt-4">
            Discover Freedom! Act Now!
          </h4>
        </div>
        <div className="ab-shape">
          <img src={abimg4} alt="" />
        </div>
      </div>
    </>
  );
}
export default AboutContent;
