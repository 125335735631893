/* eslint-disable */

import React from 'react'
import { NavLink } from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar'
import MobileMenu from '../../components/MobileMenu'
import LogoImage from '../../components/LogoImage'
import './style.scss'

const Header = () => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(	
	<div className="middle-header">
        <HeaderTopbar/>
        <div className="header-style-3">
            <div className="container">
                <div className="navbar-flex header-content">
                        {/* Stock: col-lg-3 col-md-4 col-sm-4 col-4 */}
                        {/* Previous: nav-logo col-xs-8 col-lg-8 col-md-8 col-sm-8" */}
                        <div className="nav-main nav-logo">
                            <LogoImage />
                        </div>
                        {/* Stock: col-lg-8 d-lg-block d-none */}
                        {/* Previous: col-md-2 col-lg-7 col-xl-7 d-xl-block d-none */}
                        <div className="nav-items nav-logo d-xl-inline-flex d-none">
                            <nav>
                                <ul>
                                    <li><NavLink onClick={ClickHandler} to="/home">Home</NavLink></li>
                                    <li>
                                        <NavLink onClick={ClickHandler} to="/about" title="">About</NavLink>
                                        {/* <ul>
                                            <li><NavLink to="/about" title="" onClick={ClickHandler}>About Us</NavLink></li>
                                            <li><NavLink to="/founder" title="" onClick={ClickHandler}>The Founder</NavLink></li>
                                            <li><NavLink to="/photos" title="" onClick={ClickHandler}>Photos</NavLink></li>
                                            <li><NavLink to="/stories" title="" onClick={ClickHandler}>Success Stories</NavLink></li>
                                        </ul> */}
                                    </li>
                                    {/* <li>
                                        <NavLink onClick={ClickHandler} to="/houses" title="">Houses</NavLink>
                                    </li> */}
                                    <li><NavLink onClick={ClickHandler} to="/programs" title="">Program</NavLink></li>
                                    {/* <li><NavLink onClick={ClickHandler} to="/community" title="">Community</NavLink>
                                        <ul>
                                            <li><NavLink to="/community" title="" onClick={ClickHandler}>Community</NavLink></li>
                                            <li><NavLink to="/partners" title="" onClick={ClickHandler}>Partnerships</NavLink></li>
                                        </ul>
                                    
                                    </li> */}
                                    
                                    <li><NavLink onClick={ClickHandler} to="/contact" title="">Contact</NavLink></li>
                                    <li><NavLink onClick={ClickHandler} to="/intake" title="">Intake</NavLink></li>
                                    <li><NavLink onClick={ClickHandler} to="/donate" title="">Donate</NavLink></li>

                                </ul>
                            </nav>
                        </div>
                            
                        {/* Extra Section-Stock: col-lg-1 col-md-6 col-sm-6 col-6 */}

                        {/* Stock: col-md-2 col-sm-2 col-2 */}
                        {/* Previous col-md-6 col-sm-4 col-2 d-flex flex-row-reverse */}
                        <div className="d-flex flex-row-reverse">
                            <MobileMenu />
                        </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Header;
/* eslint-enable */
