/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createClientIntake } from "../graphql/mutations";
const client = generateClient();
export default function ClientIntakeForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstName: "",
    middleInitial: "",
    lastName: "",
    preferredName: "",
    dob: "",
    age: "",
    socialSecurity: "",
    personCompletingForm: "",
    personCompletingFormRelationship: "",
    addressStreet: "",
    addressCity: "",
    addressState: "",
    addressZip: "",
    addressLivedAt: "",
    cellPhone: "",
    email: "",
    homePhone: "",
    maritialStatus: "",
    race: "",
    highestEduction: "",
    isVeteran: false,
    isActiveDuty: false,
    militaryBranch: "",
    militaryDeployed: false,
    militaryDischarge: "",
    militaryDischargeDate: "",
    arrested: false,
    arrestedCharge: "",
    convicted: false,
    convictedCharges: "",
    currentLegalConcerns: "",
    educationalExperience: "",
    employmentLevel: false,
    employmentLength: false,
    employmentConcerns: false,
    financialConcerns: false,
    generalQuestionotherPeopleSaidProblem: false,
    generalQuestionInterfered: false,
    generalQuesationArrested: false,
    generalQuesationCutBack: false,
    generalQuesationWithdrawal: false,
    generalQuesationPreoccupied: false,
    generalQuesationFocused: false,
    generalQuesationActivities: false,
    previousTreatment: "",
    insuranceProvider: "",
    insurancePolicy: "",
    insuranceGroup: "",
    insuranceExpiration: "",
    ethnicCulturalBackground: "",
    adopted: false,
    hometown: "",
    childhoodLivingArrangement: "",
    relationshipWithGuardians: "",
    ageWhenLeftHome: "",
    maritalStatus: "",
    partnershipDuration: "",
    divorcedWidowedDuration: "",
    inRelationship: false,
    relationshipDescription: "",
    sexualOrientation: "",
    haveChildren: false,
    numberOfChildren: "",
    livingArrangement: "",
    physicalAbuse: false,
    emotionalAbuse: false,
    neglect: false,
    sexualAbuseAsVictim: false,
    sexualAbuseAsPerpetrator: false,
    witnessedAbuse: false,
    familyDeaths: "",
    distressfulEvents: false,
    distressfulEventsDetails: "",
  };
  const [firstName, setFirstName] = React.useState(initialValues.firstName);
  const [middleInitial, setMiddleInitial] = React.useState(
    initialValues.middleInitial
  );
  const [lastName, setLastName] = React.useState(initialValues.lastName);
  const [preferredName, setPreferredName] = React.useState(
    initialValues.preferredName
  );
  const [dob, setDob] = React.useState(initialValues.dob);
  const [age, setAge] = React.useState(initialValues.age);
  const [socialSecurity, setSocialSecurity] = React.useState(
    initialValues.socialSecurity
  );
  const [personCompletingForm, setPersonCompletingForm] = React.useState(
    initialValues.personCompletingForm
  );
  const [
    personCompletingFormRelationship,
    setPersonCompletingFormRelationship,
  ] = React.useState(initialValues.personCompletingFormRelationship);
  const [addressStreet, setAddressStreet] = React.useState(
    initialValues.addressStreet
  );
  const [addressCity, setAddressCity] = React.useState(
    initialValues.addressCity
  );
  const [addressState, setAddressState] = React.useState(
    initialValues.addressState
  );
  const [addressZip, setAddressZip] = React.useState(initialValues.addressZip);
  const [addressLivedAt, setAddressLivedAt] = React.useState(
    initialValues.addressLivedAt
  );
  const [cellPhone, setCellPhone] = React.useState(initialValues.cellPhone);
  const [email, setEmail] = React.useState(initialValues.email);
  const [homePhone, setHomePhone] = React.useState(initialValues.homePhone);
  const [maritialStatus, setMaritialStatus] = React.useState(
    initialValues.maritialStatus
  );
  const [race, setRace] = React.useState(initialValues.race);
  const [highestEduction, setHighestEduction] = React.useState(
    initialValues.highestEduction
  );
  const [isVeteran, setIsVeteran] = React.useState(initialValues.isVeteran);
  const [isActiveDuty, setIsActiveDuty] = React.useState(
    initialValues.isActiveDuty
  );
  const [militaryBranch, setMilitaryBranch] = React.useState(
    initialValues.militaryBranch
  );
  const [militaryDeployed, setMilitaryDeployed] = React.useState(
    initialValues.militaryDeployed
  );
  const [militaryDischarge, setMilitaryDischarge] = React.useState(
    initialValues.militaryDischarge
  );
  const [militaryDischargeDate, setMilitaryDischargeDate] = React.useState(
    initialValues.militaryDischargeDate
  );
  const [arrested, setArrested] = React.useState(initialValues.arrested);
  const [arrestedCharge, setArrestedCharge] = React.useState(
    initialValues.arrestedCharge
  );
  const [convicted, setConvicted] = React.useState(initialValues.convicted);
  const [convictedCharges, setConvictedCharges] = React.useState(
    initialValues.convictedCharges
  );
  const [currentLegalConcerns, setCurrentLegalConcerns] = React.useState(
    initialValues.currentLegalConcerns
  );
  const [educationalExperience, setEducationalExperience] = React.useState(
    initialValues.educationalExperience
  );
  const [employmentLevel, setEmploymentLevel] = React.useState(
    initialValues.employmentLevel
  );
  const [employmentLength, setEmploymentLength] = React.useState(
    initialValues.employmentLength
  );
  const [employmentConcerns, setEmploymentConcerns] = React.useState(
    initialValues.employmentConcerns
  );
  const [financialConcerns, setFinancialConcerns] = React.useState(
    initialValues.financialConcerns
  );
  const [
    generalQuestionotherPeopleSaidProblem,
    setGeneralQuestionotherPeopleSaidProblem,
  ] = React.useState(initialValues.generalQuestionotherPeopleSaidProblem);
  const [generalQuestionInterfered, setGeneralQuestionInterfered] =
    React.useState(initialValues.generalQuestionInterfered);
  const [generalQuesationArrested, setGeneralQuesationArrested] =
    React.useState(initialValues.generalQuesationArrested);
  const [generalQuesationCutBack, setGeneralQuesationCutBack] = React.useState(
    initialValues.generalQuesationCutBack
  );
  const [generalQuesationWithdrawal, setGeneralQuesationWithdrawal] =
    React.useState(initialValues.generalQuesationWithdrawal);
  const [generalQuesationPreoccupied, setGeneralQuesationPreoccupied] =
    React.useState(initialValues.generalQuesationPreoccupied);
  const [generalQuesationFocused, setGeneralQuesationFocused] = React.useState(
    initialValues.generalQuesationFocused
  );
  const [generalQuesationActivities, setGeneralQuesationActivities] =
    React.useState(initialValues.generalQuesationActivities);
  const [previousTreatment, setPreviousTreatment] = React.useState(
    initialValues.previousTreatment
  );
  const [insuranceProvider, setInsuranceProvider] = React.useState(
    initialValues.insuranceProvider
  );
  const [insurancePolicy, setInsurancePolicy] = React.useState(
    initialValues.insurancePolicy
  );
  const [insuranceGroup, setInsuranceGroup] = React.useState(
    initialValues.insuranceGroup
  );
  const [insuranceExpiration, setInsuranceExpiration] = React.useState(
    initialValues.insuranceExpiration
  );
  const [ethnicCulturalBackground, setEthnicCulturalBackground] =
    React.useState(initialValues.ethnicCulturalBackground);
  const [adopted, setAdopted] = React.useState(initialValues.adopted);
  const [hometown, setHometown] = React.useState(initialValues.hometown);
  const [childhoodLivingArrangement, setChildhoodLivingArrangement] =
    React.useState(initialValues.childhoodLivingArrangement);
  const [relationshipWithGuardians, setRelationshipWithGuardians] =
    React.useState(initialValues.relationshipWithGuardians);
  const [ageWhenLeftHome, setAgeWhenLeftHome] = React.useState(
    initialValues.ageWhenLeftHome
  );
  const [maritalStatus, setMaritalStatus] = React.useState(
    initialValues.maritalStatus
  );
  const [partnershipDuration, setPartnershipDuration] = React.useState(
    initialValues.partnershipDuration
  );
  const [divorcedWidowedDuration, setDivorcedWidowedDuration] = React.useState(
    initialValues.divorcedWidowedDuration
  );
  const [inRelationship, setInRelationship] = React.useState(
    initialValues.inRelationship
  );
  const [relationshipDescription, setRelationshipDescription] = React.useState(
    initialValues.relationshipDescription
  );
  const [sexualOrientation, setSexualOrientation] = React.useState(
    initialValues.sexualOrientation
  );
  const [haveChildren, setHaveChildren] = React.useState(
    initialValues.haveChildren
  );
  const [numberOfChildren, setNumberOfChildren] = React.useState(
    initialValues.numberOfChildren
  );
  const [livingArrangement, setLivingArrangement] = React.useState(
    initialValues.livingArrangement
  );
  const [physicalAbuse, setPhysicalAbuse] = React.useState(
    initialValues.physicalAbuse
  );
  const [emotionalAbuse, setEmotionalAbuse] = React.useState(
    initialValues.emotionalAbuse
  );
  const [neglect, setNeglect] = React.useState(initialValues.neglect);
  const [sexualAbuseAsVictim, setSexualAbuseAsVictim] = React.useState(
    initialValues.sexualAbuseAsVictim
  );
  const [sexualAbuseAsPerpetrator, setSexualAbuseAsPerpetrator] =
    React.useState(initialValues.sexualAbuseAsPerpetrator);
  const [witnessedAbuse, setWitnessedAbuse] = React.useState(
    initialValues.witnessedAbuse
  );
  const [familyDeaths, setFamilyDeaths] = React.useState(
    initialValues.familyDeaths
  );
  const [distressfulEvents, setDistressfulEvents] = React.useState(
    initialValues.distressfulEvents
  );
  const [distressfulEventsDetails, setDistressfulEventsDetails] =
    React.useState(initialValues.distressfulEventsDetails);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFirstName(initialValues.firstName);
    setMiddleInitial(initialValues.middleInitial);
    setLastName(initialValues.lastName);
    setPreferredName(initialValues.preferredName);
    setDob(initialValues.dob);
    setAge(initialValues.age);
    setSocialSecurity(initialValues.socialSecurity);
    setPersonCompletingForm(initialValues.personCompletingForm);
    setPersonCompletingFormRelationship(
      initialValues.personCompletingFormRelationship
    );
    setAddressStreet(initialValues.addressStreet);
    setAddressCity(initialValues.addressCity);
    setAddressState(initialValues.addressState);
    setAddressZip(initialValues.addressZip);
    setAddressLivedAt(initialValues.addressLivedAt);
    setCellPhone(initialValues.cellPhone);
    setEmail(initialValues.email);
    setHomePhone(initialValues.homePhone);
    setMaritialStatus(initialValues.maritialStatus);
    setRace(initialValues.race);
    setHighestEduction(initialValues.highestEduction);
    setIsVeteran(initialValues.isVeteran);
    setIsActiveDuty(initialValues.isActiveDuty);
    setMilitaryBranch(initialValues.militaryBranch);
    setMilitaryDeployed(initialValues.militaryDeployed);
    setMilitaryDischarge(initialValues.militaryDischarge);
    setMilitaryDischargeDate(initialValues.militaryDischargeDate);
    setArrested(initialValues.arrested);
    setArrestedCharge(initialValues.arrestedCharge);
    setConvicted(initialValues.convicted);
    setConvictedCharges(initialValues.convictedCharges);
    setCurrentLegalConcerns(initialValues.currentLegalConcerns);
    setEducationalExperience(initialValues.educationalExperience);
    setEmploymentLevel(initialValues.employmentLevel);
    setEmploymentLength(initialValues.employmentLength);
    setEmploymentConcerns(initialValues.employmentConcerns);
    setFinancialConcerns(initialValues.financialConcerns);
    setGeneralQuestionotherPeopleSaidProblem(
      initialValues.generalQuestionotherPeopleSaidProblem
    );
    setGeneralQuestionInterfered(initialValues.generalQuestionInterfered);
    setGeneralQuesationArrested(initialValues.generalQuesationArrested);
    setGeneralQuesationCutBack(initialValues.generalQuesationCutBack);
    setGeneralQuesationWithdrawal(initialValues.generalQuesationWithdrawal);
    setGeneralQuesationPreoccupied(initialValues.generalQuesationPreoccupied);
    setGeneralQuesationFocused(initialValues.generalQuesationFocused);
    setGeneralQuesationActivities(initialValues.generalQuesationActivities);
    setPreviousTreatment(initialValues.previousTreatment);
    setInsuranceProvider(initialValues.insuranceProvider);
    setInsurancePolicy(initialValues.insurancePolicy);
    setInsuranceGroup(initialValues.insuranceGroup);
    setInsuranceExpiration(initialValues.insuranceExpiration);
    setEthnicCulturalBackground(initialValues.ethnicCulturalBackground);
    setAdopted(initialValues.adopted);
    setHometown(initialValues.hometown);
    setChildhoodLivingArrangement(initialValues.childhoodLivingArrangement);
    setRelationshipWithGuardians(initialValues.relationshipWithGuardians);
    setAgeWhenLeftHome(initialValues.ageWhenLeftHome);
    setMaritalStatus(initialValues.maritalStatus);
    setPartnershipDuration(initialValues.partnershipDuration);
    setDivorcedWidowedDuration(initialValues.divorcedWidowedDuration);
    setInRelationship(initialValues.inRelationship);
    setRelationshipDescription(initialValues.relationshipDescription);
    setSexualOrientation(initialValues.sexualOrientation);
    setHaveChildren(initialValues.haveChildren);
    setNumberOfChildren(initialValues.numberOfChildren);
    setLivingArrangement(initialValues.livingArrangement);
    setPhysicalAbuse(initialValues.physicalAbuse);
    setEmotionalAbuse(initialValues.emotionalAbuse);
    setNeglect(initialValues.neglect);
    setSexualAbuseAsVictim(initialValues.sexualAbuseAsVictim);
    setSexualAbuseAsPerpetrator(initialValues.sexualAbuseAsPerpetrator);
    setWitnessedAbuse(initialValues.witnessedAbuse);
    setFamilyDeaths(initialValues.familyDeaths);
    setDistressfulEvents(initialValues.distressfulEvents);
    setDistressfulEventsDetails(initialValues.distressfulEventsDetails);
    setErrors({});
  };
  const validations = {
    firstName: [],
    middleInitial: [],
    lastName: [],
    preferredName: [],
    dob: [],
    age: [],
    socialSecurity: [],
    personCompletingForm: [],
    personCompletingFormRelationship: [],
    addressStreet: [],
    addressCity: [],
    addressState: [],
    addressZip: [],
    addressLivedAt: [],
    cellPhone: [{ type: "Phone" }],
    email: [{ type: "Email" }],
    homePhone: [{ type: "Phone" }],
    maritialStatus: [],
    race: [],
    highestEduction: [],
    isVeteran: [],
    isActiveDuty: [],
    militaryBranch: [],
    militaryDeployed: [],
    militaryDischarge: [],
    militaryDischargeDate: [],
    arrested: [],
    arrestedCharge: [],
    convicted: [],
    convictedCharges: [],
    currentLegalConcerns: [],
    educationalExperience: [],
    employmentLevel: [],
    employmentLength: [],
    employmentConcerns: [],
    financialConcerns: [],
    generalQuestionotherPeopleSaidProblem: [],
    generalQuestionInterfered: [],
    generalQuesationArrested: [],
    generalQuesationCutBack: [],
    generalQuesationWithdrawal: [],
    generalQuesationPreoccupied: [],
    generalQuesationFocused: [],
    generalQuesationActivities: [],
    previousTreatment: [],
    insuranceProvider: [],
    insurancePolicy: [],
    insuranceGroup: [],
    insuranceExpiration: [],
    ethnicCulturalBackground: [],
    adopted: [{ type: "Required" }],
    hometown: [],
    childhoodLivingArrangement: [],
    relationshipWithGuardians: [],
    ageWhenLeftHome: [],
    maritalStatus: [],
    partnershipDuration: [],
    divorcedWidowedDuration: [],
    inRelationship: [{ type: "Required" }],
    relationshipDescription: [],
    sexualOrientation: [],
    haveChildren: [{ type: "Required" }],
    numberOfChildren: [],
    livingArrangement: [],
    physicalAbuse: [{ type: "Required" }],
    emotionalAbuse: [{ type: "Required" }],
    neglect: [{ type: "Required" }],
    sexualAbuseAsVictim: [{ type: "Required" }],
    sexualAbuseAsPerpetrator: [{ type: "Required" }],
    witnessedAbuse: [{ type: "Required" }],
    familyDeaths: [],
    distressfulEvents: [{ type: "Required" }],
    distressfulEventsDetails: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstName,
          middleInitial,
          lastName,
          preferredName,
          dob,
          age,
          socialSecurity,
          personCompletingForm,
          personCompletingFormRelationship,
          addressStreet,
          addressCity,
          addressState,
          addressZip,
          addressLivedAt,
          cellPhone,
          email,
          homePhone,
          maritialStatus,
          race,
          highestEduction,
          isVeteran,
          isActiveDuty,
          militaryBranch,
          militaryDeployed,
          militaryDischarge,
          militaryDischargeDate,
          arrested,
          arrestedCharge,
          convicted,
          convictedCharges,
          currentLegalConcerns,
          educationalExperience,
          employmentLevel,
          employmentLength,
          employmentConcerns,
          financialConcerns,
          generalQuestionotherPeopleSaidProblem,
          generalQuestionInterfered,
          generalQuesationArrested,
          generalQuesationCutBack,
          generalQuesationWithdrawal,
          generalQuesationPreoccupied,
          generalQuesationFocused,
          generalQuesationActivities,
          previousTreatment,
          insuranceProvider,
          insurancePolicy,
          insuranceGroup,
          insuranceExpiration,
          ethnicCulturalBackground,
          adopted,
          hometown,
          childhoodLivingArrangement,
          relationshipWithGuardians,
          ageWhenLeftHome,
          maritalStatus,
          partnershipDuration,
          divorcedWidowedDuration,
          inRelationship,
          relationshipDescription,
          sexualOrientation,
          haveChildren,
          numberOfChildren,
          livingArrangement,
          physicalAbuse,
          emotionalAbuse,
          neglect,
          sexualAbuseAsVictim,
          sexualAbuseAsPerpetrator,
          witnessedAbuse,
          familyDeaths,
          distressfulEvents,
          distressfulEventsDetails,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createClientIntake.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClientIntakeForm")}
      {...rest}
    >
      <TextField
        label="First name"
        isRequired={false}
        isReadOnly={false}
        value={firstName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName: value,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.firstName ?? value;
          }
          if (errors.firstName?.hasError) {
            runValidationTasks("firstName", value);
          }
          setFirstName(value);
        }}
        onBlur={() => runValidationTasks("firstName", firstName)}
        errorMessage={errors.firstName?.errorMessage}
        hasError={errors.firstName?.hasError}
        {...getOverrideProps(overrides, "firstName")}
      ></TextField>
      <TextField
        label="Middle initial"
        isRequired={false}
        isReadOnly={false}
        value={middleInitial}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial: value,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.middleInitial ?? value;
          }
          if (errors.middleInitial?.hasError) {
            runValidationTasks("middleInitial", value);
          }
          setMiddleInitial(value);
        }}
        onBlur={() => runValidationTasks("middleInitial", middleInitial)}
        errorMessage={errors.middleInitial?.errorMessage}
        hasError={errors.middleInitial?.hasError}
        {...getOverrideProps(overrides, "middleInitial")}
      ></TextField>
      <TextField
        label="Last name"
        isRequired={false}
        isReadOnly={false}
        value={lastName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName: value,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.lastName ?? value;
          }
          if (errors.lastName?.hasError) {
            runValidationTasks("lastName", value);
          }
          setLastName(value);
        }}
        onBlur={() => runValidationTasks("lastName", lastName)}
        errorMessage={errors.lastName?.errorMessage}
        hasError={errors.lastName?.hasError}
        {...getOverrideProps(overrides, "lastName")}
      ></TextField>
      <TextField
        label="Preferred name"
        isRequired={false}
        isReadOnly={false}
        value={preferredName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName: value,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.preferredName ?? value;
          }
          if (errors.preferredName?.hasError) {
            runValidationTasks("preferredName", value);
          }
          setPreferredName(value);
        }}
        onBlur={() => runValidationTasks("preferredName", preferredName)}
        errorMessage={errors.preferredName?.errorMessage}
        hasError={errors.preferredName?.hasError}
        {...getOverrideProps(overrides, "preferredName")}
      ></TextField>
      <TextField
        label="Dob"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={dob}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob: value,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.dob ?? value;
          }
          if (errors.dob?.hasError) {
            runValidationTasks("dob", value);
          }
          setDob(value);
        }}
        onBlur={() => runValidationTasks("dob", dob)}
        errorMessage={errors.dob?.errorMessage}
        hasError={errors.dob?.hasError}
        {...getOverrideProps(overrides, "dob")}
      ></TextField>
      <TextField
        label="Age"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={age}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age: value,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.age ?? value;
          }
          if (errors.age?.hasError) {
            runValidationTasks("age", value);
          }
          setAge(value);
        }}
        onBlur={() => runValidationTasks("age", age)}
        errorMessage={errors.age?.errorMessage}
        hasError={errors.age?.hasError}
        {...getOverrideProps(overrides, "age")}
      ></TextField>
      <TextField
        label="Social security"
        isRequired={false}
        isReadOnly={false}
        value={socialSecurity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity: value,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.socialSecurity ?? value;
          }
          if (errors.socialSecurity?.hasError) {
            runValidationTasks("socialSecurity", value);
          }
          setSocialSecurity(value);
        }}
        onBlur={() => runValidationTasks("socialSecurity", socialSecurity)}
        errorMessage={errors.socialSecurity?.errorMessage}
        hasError={errors.socialSecurity?.hasError}
        {...getOverrideProps(overrides, "socialSecurity")}
      ></TextField>
      <TextField
        label="Person completing form"
        isRequired={false}
        isReadOnly={false}
        value={personCompletingForm}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm: value,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.personCompletingForm ?? value;
          }
          if (errors.personCompletingForm?.hasError) {
            runValidationTasks("personCompletingForm", value);
          }
          setPersonCompletingForm(value);
        }}
        onBlur={() =>
          runValidationTasks("personCompletingForm", personCompletingForm)
        }
        errorMessage={errors.personCompletingForm?.errorMessage}
        hasError={errors.personCompletingForm?.hasError}
        {...getOverrideProps(overrides, "personCompletingForm")}
      ></TextField>
      <TextField
        label="Person completing form relationship"
        isRequired={false}
        isReadOnly={false}
        value={personCompletingFormRelationship}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship: value,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.personCompletingFormRelationship ?? value;
          }
          if (errors.personCompletingFormRelationship?.hasError) {
            runValidationTasks("personCompletingFormRelationship", value);
          }
          setPersonCompletingFormRelationship(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "personCompletingFormRelationship",
            personCompletingFormRelationship
          )
        }
        errorMessage={errors.personCompletingFormRelationship?.errorMessage}
        hasError={errors.personCompletingFormRelationship?.hasError}
        {...getOverrideProps(overrides, "personCompletingFormRelationship")}
      ></TextField>
      <TextField
        label="Address street"
        isRequired={false}
        isReadOnly={false}
        value={addressStreet}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet: value,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.addressStreet ?? value;
          }
          if (errors.addressStreet?.hasError) {
            runValidationTasks("addressStreet", value);
          }
          setAddressStreet(value);
        }}
        onBlur={() => runValidationTasks("addressStreet", addressStreet)}
        errorMessage={errors.addressStreet?.errorMessage}
        hasError={errors.addressStreet?.hasError}
        {...getOverrideProps(overrides, "addressStreet")}
      ></TextField>
      <TextField
        label="Address city"
        isRequired={false}
        isReadOnly={false}
        value={addressCity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity: value,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.addressCity ?? value;
          }
          if (errors.addressCity?.hasError) {
            runValidationTasks("addressCity", value);
          }
          setAddressCity(value);
        }}
        onBlur={() => runValidationTasks("addressCity", addressCity)}
        errorMessage={errors.addressCity?.errorMessage}
        hasError={errors.addressCity?.hasError}
        {...getOverrideProps(overrides, "addressCity")}
      ></TextField>
      <TextField
        label="Address state"
        isRequired={false}
        isReadOnly={false}
        value={addressState}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState: value,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.addressState ?? value;
          }
          if (errors.addressState?.hasError) {
            runValidationTasks("addressState", value);
          }
          setAddressState(value);
        }}
        onBlur={() => runValidationTasks("addressState", addressState)}
        errorMessage={errors.addressState?.errorMessage}
        hasError={errors.addressState?.hasError}
        {...getOverrideProps(overrides, "addressState")}
      ></TextField>
      <TextField
        label="Address zip"
        isRequired={false}
        isReadOnly={false}
        value={addressZip}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip: value,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.addressZip ?? value;
          }
          if (errors.addressZip?.hasError) {
            runValidationTasks("addressZip", value);
          }
          setAddressZip(value);
        }}
        onBlur={() => runValidationTasks("addressZip", addressZip)}
        errorMessage={errors.addressZip?.errorMessage}
        hasError={errors.addressZip?.hasError}
        {...getOverrideProps(overrides, "addressZip")}
      ></TextField>
      <TextField
        label="Address lived at"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={addressLivedAt}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt: value,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.addressLivedAt ?? value;
          }
          if (errors.addressLivedAt?.hasError) {
            runValidationTasks("addressLivedAt", value);
          }
          setAddressLivedAt(value);
        }}
        onBlur={() => runValidationTasks("addressLivedAt", addressLivedAt)}
        errorMessage={errors.addressLivedAt?.errorMessage}
        hasError={errors.addressLivedAt?.hasError}
        {...getOverrideProps(overrides, "addressLivedAt")}
      ></TextField>
      <TextField
        label="Cell phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={cellPhone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone: value,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.cellPhone ?? value;
          }
          if (errors.cellPhone?.hasError) {
            runValidationTasks("cellPhone", value);
          }
          setCellPhone(value);
        }}
        onBlur={() => runValidationTasks("cellPhone", cellPhone)}
        errorMessage={errors.cellPhone?.errorMessage}
        hasError={errors.cellPhone?.hasError}
        {...getOverrideProps(overrides, "cellPhone")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email: value,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Home phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={homePhone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone: value,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.homePhone ?? value;
          }
          if (errors.homePhone?.hasError) {
            runValidationTasks("homePhone", value);
          }
          setHomePhone(value);
        }}
        onBlur={() => runValidationTasks("homePhone", homePhone)}
        errorMessage={errors.homePhone?.errorMessage}
        hasError={errors.homePhone?.hasError}
        {...getOverrideProps(overrides, "homePhone")}
      ></TextField>
      <TextField
        label="Maritial status"
        isRequired={false}
        isReadOnly={false}
        value={maritialStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus: value,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.maritialStatus ?? value;
          }
          if (errors.maritialStatus?.hasError) {
            runValidationTasks("maritialStatus", value);
          }
          setMaritialStatus(value);
        }}
        onBlur={() => runValidationTasks("maritialStatus", maritialStatus)}
        errorMessage={errors.maritialStatus?.errorMessage}
        hasError={errors.maritialStatus?.hasError}
        {...getOverrideProps(overrides, "maritialStatus")}
      ></TextField>
      <TextField
        label="Race"
        isRequired={false}
        isReadOnly={false}
        value={race}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race: value,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.race ?? value;
          }
          if (errors.race?.hasError) {
            runValidationTasks("race", value);
          }
          setRace(value);
        }}
        onBlur={() => runValidationTasks("race", race)}
        errorMessage={errors.race?.errorMessage}
        hasError={errors.race?.hasError}
        {...getOverrideProps(overrides, "race")}
      ></TextField>
      <TextField
        label="Highest eduction"
        isRequired={false}
        isReadOnly={false}
        value={highestEduction}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction: value,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.highestEduction ?? value;
          }
          if (errors.highestEduction?.hasError) {
            runValidationTasks("highestEduction", value);
          }
          setHighestEduction(value);
        }}
        onBlur={() => runValidationTasks("highestEduction", highestEduction)}
        errorMessage={errors.highestEduction?.errorMessage}
        hasError={errors.highestEduction?.hasError}
        {...getOverrideProps(overrides, "highestEduction")}
      ></TextField>
      <SwitchField
        label="Is veteran"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isVeteran}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran: value,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.isVeteran ?? value;
          }
          if (errors.isVeteran?.hasError) {
            runValidationTasks("isVeteran", value);
          }
          setIsVeteran(value);
        }}
        onBlur={() => runValidationTasks("isVeteran", isVeteran)}
        errorMessage={errors.isVeteran?.errorMessage}
        hasError={errors.isVeteran?.hasError}
        {...getOverrideProps(overrides, "isVeteran")}
      ></SwitchField>
      <SwitchField
        label="Is active duty"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isActiveDuty}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty: value,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.isActiveDuty ?? value;
          }
          if (errors.isActiveDuty?.hasError) {
            runValidationTasks("isActiveDuty", value);
          }
          setIsActiveDuty(value);
        }}
        onBlur={() => runValidationTasks("isActiveDuty", isActiveDuty)}
        errorMessage={errors.isActiveDuty?.errorMessage}
        hasError={errors.isActiveDuty?.hasError}
        {...getOverrideProps(overrides, "isActiveDuty")}
      ></SwitchField>
      <TextField
        label="Military branch"
        isRequired={false}
        isReadOnly={false}
        value={militaryBranch}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch: value,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.militaryBranch ?? value;
          }
          if (errors.militaryBranch?.hasError) {
            runValidationTasks("militaryBranch", value);
          }
          setMilitaryBranch(value);
        }}
        onBlur={() => runValidationTasks("militaryBranch", militaryBranch)}
        errorMessage={errors.militaryBranch?.errorMessage}
        hasError={errors.militaryBranch?.hasError}
        {...getOverrideProps(overrides, "militaryBranch")}
      ></TextField>
      <SwitchField
        label="Military deployed"
        defaultChecked={false}
        isDisabled={false}
        isChecked={militaryDeployed}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed: value,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.militaryDeployed ?? value;
          }
          if (errors.militaryDeployed?.hasError) {
            runValidationTasks("militaryDeployed", value);
          }
          setMilitaryDeployed(value);
        }}
        onBlur={() => runValidationTasks("militaryDeployed", militaryDeployed)}
        errorMessage={errors.militaryDeployed?.errorMessage}
        hasError={errors.militaryDeployed?.hasError}
        {...getOverrideProps(overrides, "militaryDeployed")}
      ></SwitchField>
      <TextField
        label="Military discharge"
        isRequired={false}
        isReadOnly={false}
        value={militaryDischarge}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge: value,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.militaryDischarge ?? value;
          }
          if (errors.militaryDischarge?.hasError) {
            runValidationTasks("militaryDischarge", value);
          }
          setMilitaryDischarge(value);
        }}
        onBlur={() =>
          runValidationTasks("militaryDischarge", militaryDischarge)
        }
        errorMessage={errors.militaryDischarge?.errorMessage}
        hasError={errors.militaryDischarge?.hasError}
        {...getOverrideProps(overrides, "militaryDischarge")}
      ></TextField>
      <TextField
        label="Military discharge date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={militaryDischargeDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate: value,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.militaryDischargeDate ?? value;
          }
          if (errors.militaryDischargeDate?.hasError) {
            runValidationTasks("militaryDischargeDate", value);
          }
          setMilitaryDischargeDate(value);
        }}
        onBlur={() =>
          runValidationTasks("militaryDischargeDate", militaryDischargeDate)
        }
        errorMessage={errors.militaryDischargeDate?.errorMessage}
        hasError={errors.militaryDischargeDate?.hasError}
        {...getOverrideProps(overrides, "militaryDischargeDate")}
      ></TextField>
      <SwitchField
        label="Arrested"
        defaultChecked={false}
        isDisabled={false}
        isChecked={arrested}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested: value,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.arrested ?? value;
          }
          if (errors.arrested?.hasError) {
            runValidationTasks("arrested", value);
          }
          setArrested(value);
        }}
        onBlur={() => runValidationTasks("arrested", arrested)}
        errorMessage={errors.arrested?.errorMessage}
        hasError={errors.arrested?.hasError}
        {...getOverrideProps(overrides, "arrested")}
      ></SwitchField>
      <TextField
        label="Arrested charge"
        isRequired={false}
        isReadOnly={false}
        value={arrestedCharge}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge: value,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.arrestedCharge ?? value;
          }
          if (errors.arrestedCharge?.hasError) {
            runValidationTasks("arrestedCharge", value);
          }
          setArrestedCharge(value);
        }}
        onBlur={() => runValidationTasks("arrestedCharge", arrestedCharge)}
        errorMessage={errors.arrestedCharge?.errorMessage}
        hasError={errors.arrestedCharge?.hasError}
        {...getOverrideProps(overrides, "arrestedCharge")}
      ></TextField>
      <SwitchField
        label="Convicted"
        defaultChecked={false}
        isDisabled={false}
        isChecked={convicted}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted: value,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.convicted ?? value;
          }
          if (errors.convicted?.hasError) {
            runValidationTasks("convicted", value);
          }
          setConvicted(value);
        }}
        onBlur={() => runValidationTasks("convicted", convicted)}
        errorMessage={errors.convicted?.errorMessage}
        hasError={errors.convicted?.hasError}
        {...getOverrideProps(overrides, "convicted")}
      ></SwitchField>
      <TextField
        label="Convicted charges"
        isRequired={false}
        isReadOnly={false}
        value={convictedCharges}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges: value,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.convictedCharges ?? value;
          }
          if (errors.convictedCharges?.hasError) {
            runValidationTasks("convictedCharges", value);
          }
          setConvictedCharges(value);
        }}
        onBlur={() => runValidationTasks("convictedCharges", convictedCharges)}
        errorMessage={errors.convictedCharges?.errorMessage}
        hasError={errors.convictedCharges?.hasError}
        {...getOverrideProps(overrides, "convictedCharges")}
      ></TextField>
      <TextField
        label="Current legal concerns"
        isRequired={false}
        isReadOnly={false}
        value={currentLegalConcerns}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns: value,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.currentLegalConcerns ?? value;
          }
          if (errors.currentLegalConcerns?.hasError) {
            runValidationTasks("currentLegalConcerns", value);
          }
          setCurrentLegalConcerns(value);
        }}
        onBlur={() =>
          runValidationTasks("currentLegalConcerns", currentLegalConcerns)
        }
        errorMessage={errors.currentLegalConcerns?.errorMessage}
        hasError={errors.currentLegalConcerns?.hasError}
        {...getOverrideProps(overrides, "currentLegalConcerns")}
      ></TextField>
      <TextField
        label="Educational experience"
        isRequired={false}
        isReadOnly={false}
        value={educationalExperience}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience: value,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.educationalExperience ?? value;
          }
          if (errors.educationalExperience?.hasError) {
            runValidationTasks("educationalExperience", value);
          }
          setEducationalExperience(value);
        }}
        onBlur={() =>
          runValidationTasks("educationalExperience", educationalExperience)
        }
        errorMessage={errors.educationalExperience?.errorMessage}
        hasError={errors.educationalExperience?.hasError}
        {...getOverrideProps(overrides, "educationalExperience")}
      ></TextField>
      <SwitchField
        label="Employment level"
        defaultChecked={false}
        isDisabled={false}
        isChecked={employmentLevel}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel: value,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.employmentLevel ?? value;
          }
          if (errors.employmentLevel?.hasError) {
            runValidationTasks("employmentLevel", value);
          }
          setEmploymentLevel(value);
        }}
        onBlur={() => runValidationTasks("employmentLevel", employmentLevel)}
        errorMessage={errors.employmentLevel?.errorMessage}
        hasError={errors.employmentLevel?.hasError}
        {...getOverrideProps(overrides, "employmentLevel")}
      ></SwitchField>
      <SwitchField
        label="Employment length"
        defaultChecked={false}
        isDisabled={false}
        isChecked={employmentLength}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength: value,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.employmentLength ?? value;
          }
          if (errors.employmentLength?.hasError) {
            runValidationTasks("employmentLength", value);
          }
          setEmploymentLength(value);
        }}
        onBlur={() => runValidationTasks("employmentLength", employmentLength)}
        errorMessage={errors.employmentLength?.errorMessage}
        hasError={errors.employmentLength?.hasError}
        {...getOverrideProps(overrides, "employmentLength")}
      ></SwitchField>
      <SwitchField
        label="Employment concerns"
        defaultChecked={false}
        isDisabled={false}
        isChecked={employmentConcerns}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns: value,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.employmentConcerns ?? value;
          }
          if (errors.employmentConcerns?.hasError) {
            runValidationTasks("employmentConcerns", value);
          }
          setEmploymentConcerns(value);
        }}
        onBlur={() =>
          runValidationTasks("employmentConcerns", employmentConcerns)
        }
        errorMessage={errors.employmentConcerns?.errorMessage}
        hasError={errors.employmentConcerns?.hasError}
        {...getOverrideProps(overrides, "employmentConcerns")}
      ></SwitchField>
      <SwitchField
        label="Financial concerns"
        defaultChecked={false}
        isDisabled={false}
        isChecked={financialConcerns}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns: value,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.financialConcerns ?? value;
          }
          if (errors.financialConcerns?.hasError) {
            runValidationTasks("financialConcerns", value);
          }
          setFinancialConcerns(value);
        }}
        onBlur={() =>
          runValidationTasks("financialConcerns", financialConcerns)
        }
        errorMessage={errors.financialConcerns?.errorMessage}
        hasError={errors.financialConcerns?.hasError}
        {...getOverrideProps(overrides, "financialConcerns")}
      ></SwitchField>
      <SwitchField
        label="General questionother people said problem"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuestionotherPeopleSaidProblem}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem: value,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuestionotherPeopleSaidProblem ?? value;
          }
          if (errors.generalQuestionotherPeopleSaidProblem?.hasError) {
            runValidationTasks("generalQuestionotherPeopleSaidProblem", value);
          }
          setGeneralQuestionotherPeopleSaidProblem(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generalQuestionotherPeopleSaidProblem",
            generalQuestionotherPeopleSaidProblem
          )
        }
        errorMessage={
          errors.generalQuestionotherPeopleSaidProblem?.errorMessage
        }
        hasError={errors.generalQuestionotherPeopleSaidProblem?.hasError}
        {...getOverrideProps(
          overrides,
          "generalQuestionotherPeopleSaidProblem"
        )}
      ></SwitchField>
      <SwitchField
        label="General question interfered"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuestionInterfered}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered: value,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuestionInterfered ?? value;
          }
          if (errors.generalQuestionInterfered?.hasError) {
            runValidationTasks("generalQuestionInterfered", value);
          }
          setGeneralQuestionInterfered(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generalQuestionInterfered",
            generalQuestionInterfered
          )
        }
        errorMessage={errors.generalQuestionInterfered?.errorMessage}
        hasError={errors.generalQuestionInterfered?.hasError}
        {...getOverrideProps(overrides, "generalQuestionInterfered")}
      ></SwitchField>
      <SwitchField
        label="General quesation arrested"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuesationArrested}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested: value,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuesationArrested ?? value;
          }
          if (errors.generalQuesationArrested?.hasError) {
            runValidationTasks("generalQuesationArrested", value);
          }
          setGeneralQuesationArrested(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generalQuesationArrested",
            generalQuesationArrested
          )
        }
        errorMessage={errors.generalQuesationArrested?.errorMessage}
        hasError={errors.generalQuesationArrested?.hasError}
        {...getOverrideProps(overrides, "generalQuesationArrested")}
      ></SwitchField>
      <SwitchField
        label="General quesation cut back"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuesationCutBack}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack: value,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuesationCutBack ?? value;
          }
          if (errors.generalQuesationCutBack?.hasError) {
            runValidationTasks("generalQuesationCutBack", value);
          }
          setGeneralQuesationCutBack(value);
        }}
        onBlur={() =>
          runValidationTasks("generalQuesationCutBack", generalQuesationCutBack)
        }
        errorMessage={errors.generalQuesationCutBack?.errorMessage}
        hasError={errors.generalQuesationCutBack?.hasError}
        {...getOverrideProps(overrides, "generalQuesationCutBack")}
      ></SwitchField>
      <SwitchField
        label="General quesation withdrawal"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuesationWithdrawal}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal: value,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuesationWithdrawal ?? value;
          }
          if (errors.generalQuesationWithdrawal?.hasError) {
            runValidationTasks("generalQuesationWithdrawal", value);
          }
          setGeneralQuesationWithdrawal(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generalQuesationWithdrawal",
            generalQuesationWithdrawal
          )
        }
        errorMessage={errors.generalQuesationWithdrawal?.errorMessage}
        hasError={errors.generalQuesationWithdrawal?.hasError}
        {...getOverrideProps(overrides, "generalQuesationWithdrawal")}
      ></SwitchField>
      <SwitchField
        label="General quesation preoccupied"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuesationPreoccupied}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied: value,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuesationPreoccupied ?? value;
          }
          if (errors.generalQuesationPreoccupied?.hasError) {
            runValidationTasks("generalQuesationPreoccupied", value);
          }
          setGeneralQuesationPreoccupied(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generalQuesationPreoccupied",
            generalQuesationPreoccupied
          )
        }
        errorMessage={errors.generalQuesationPreoccupied?.errorMessage}
        hasError={errors.generalQuesationPreoccupied?.hasError}
        {...getOverrideProps(overrides, "generalQuesationPreoccupied")}
      ></SwitchField>
      <SwitchField
        label="General quesation focused"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuesationFocused}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused: value,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuesationFocused ?? value;
          }
          if (errors.generalQuesationFocused?.hasError) {
            runValidationTasks("generalQuesationFocused", value);
          }
          setGeneralQuesationFocused(value);
        }}
        onBlur={() =>
          runValidationTasks("generalQuesationFocused", generalQuesationFocused)
        }
        errorMessage={errors.generalQuesationFocused?.errorMessage}
        hasError={errors.generalQuesationFocused?.hasError}
        {...getOverrideProps(overrides, "generalQuesationFocused")}
      ></SwitchField>
      <SwitchField
        label="General quesation activities"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generalQuesationActivities}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities: value,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.generalQuesationActivities ?? value;
          }
          if (errors.generalQuesationActivities?.hasError) {
            runValidationTasks("generalQuesationActivities", value);
          }
          setGeneralQuesationActivities(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generalQuesationActivities",
            generalQuesationActivities
          )
        }
        errorMessage={errors.generalQuesationActivities?.errorMessage}
        hasError={errors.generalQuesationActivities?.hasError}
        {...getOverrideProps(overrides, "generalQuesationActivities")}
      ></SwitchField>
      <TextField
        label="Previous treatment"
        isRequired={false}
        isReadOnly={false}
        value={previousTreatment}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment: value,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.previousTreatment ?? value;
          }
          if (errors.previousTreatment?.hasError) {
            runValidationTasks("previousTreatment", value);
          }
          setPreviousTreatment(value);
        }}
        onBlur={() =>
          runValidationTasks("previousTreatment", previousTreatment)
        }
        errorMessage={errors.previousTreatment?.errorMessage}
        hasError={errors.previousTreatment?.hasError}
        {...getOverrideProps(overrides, "previousTreatment")}
      ></TextField>
      <TextField
        label="Insurance provider"
        isRequired={false}
        isReadOnly={false}
        value={insuranceProvider}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider: value,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.insuranceProvider ?? value;
          }
          if (errors.insuranceProvider?.hasError) {
            runValidationTasks("insuranceProvider", value);
          }
          setInsuranceProvider(value);
        }}
        onBlur={() =>
          runValidationTasks("insuranceProvider", insuranceProvider)
        }
        errorMessage={errors.insuranceProvider?.errorMessage}
        hasError={errors.insuranceProvider?.hasError}
        {...getOverrideProps(overrides, "insuranceProvider")}
      ></TextField>
      <TextField
        label="Insurance policy"
        isRequired={false}
        isReadOnly={false}
        value={insurancePolicy}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy: value,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.insurancePolicy ?? value;
          }
          if (errors.insurancePolicy?.hasError) {
            runValidationTasks("insurancePolicy", value);
          }
          setInsurancePolicy(value);
        }}
        onBlur={() => runValidationTasks("insurancePolicy", insurancePolicy)}
        errorMessage={errors.insurancePolicy?.errorMessage}
        hasError={errors.insurancePolicy?.hasError}
        {...getOverrideProps(overrides, "insurancePolicy")}
      ></TextField>
      <TextField
        label="Insurance group"
        isRequired={false}
        isReadOnly={false}
        value={insuranceGroup}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup: value,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.insuranceGroup ?? value;
          }
          if (errors.insuranceGroup?.hasError) {
            runValidationTasks("insuranceGroup", value);
          }
          setInsuranceGroup(value);
        }}
        onBlur={() => runValidationTasks("insuranceGroup", insuranceGroup)}
        errorMessage={errors.insuranceGroup?.errorMessage}
        hasError={errors.insuranceGroup?.hasError}
        {...getOverrideProps(overrides, "insuranceGroup")}
      ></TextField>
      <TextField
        label="Insurance expiration"
        isRequired={false}
        isReadOnly={false}
        value={insuranceExpiration}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration: value,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.insuranceExpiration ?? value;
          }
          if (errors.insuranceExpiration?.hasError) {
            runValidationTasks("insuranceExpiration", value);
          }
          setInsuranceExpiration(value);
        }}
        onBlur={() =>
          runValidationTasks("insuranceExpiration", insuranceExpiration)
        }
        errorMessage={errors.insuranceExpiration?.errorMessage}
        hasError={errors.insuranceExpiration?.hasError}
        {...getOverrideProps(overrides, "insuranceExpiration")}
      ></TextField>
      <TextField
        label="Ethnic cultural background"
        isRequired={false}
        isReadOnly={false}
        value={ethnicCulturalBackground}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground: value,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.ethnicCulturalBackground ?? value;
          }
          if (errors.ethnicCulturalBackground?.hasError) {
            runValidationTasks("ethnicCulturalBackground", value);
          }
          setEthnicCulturalBackground(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ethnicCulturalBackground",
            ethnicCulturalBackground
          )
        }
        errorMessage={errors.ethnicCulturalBackground?.errorMessage}
        hasError={errors.ethnicCulturalBackground?.hasError}
        {...getOverrideProps(overrides, "ethnicCulturalBackground")}
      ></TextField>
      <SwitchField
        label="Adopted"
        defaultChecked={false}
        isDisabled={false}
        isChecked={adopted}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted: value,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.adopted ?? value;
          }
          if (errors.adopted?.hasError) {
            runValidationTasks("adopted", value);
          }
          setAdopted(value);
        }}
        onBlur={() => runValidationTasks("adopted", adopted)}
        errorMessage={errors.adopted?.errorMessage}
        hasError={errors.adopted?.hasError}
        {...getOverrideProps(overrides, "adopted")}
      ></SwitchField>
      <TextField
        label="Hometown"
        isRequired={false}
        isReadOnly={false}
        value={hometown}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown: value,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.hometown ?? value;
          }
          if (errors.hometown?.hasError) {
            runValidationTasks("hometown", value);
          }
          setHometown(value);
        }}
        onBlur={() => runValidationTasks("hometown", hometown)}
        errorMessage={errors.hometown?.errorMessage}
        hasError={errors.hometown?.hasError}
        {...getOverrideProps(overrides, "hometown")}
      ></TextField>
      <TextField
        label="Childhood living arrangement"
        isRequired={false}
        isReadOnly={false}
        value={childhoodLivingArrangement}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement: value,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.childhoodLivingArrangement ?? value;
          }
          if (errors.childhoodLivingArrangement?.hasError) {
            runValidationTasks("childhoodLivingArrangement", value);
          }
          setChildhoodLivingArrangement(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "childhoodLivingArrangement",
            childhoodLivingArrangement
          )
        }
        errorMessage={errors.childhoodLivingArrangement?.errorMessage}
        hasError={errors.childhoodLivingArrangement?.hasError}
        {...getOverrideProps(overrides, "childhoodLivingArrangement")}
      ></TextField>
      <TextField
        label="Relationship with guardians"
        isRequired={false}
        isReadOnly={false}
        value={relationshipWithGuardians}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians: value,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.relationshipWithGuardians ?? value;
          }
          if (errors.relationshipWithGuardians?.hasError) {
            runValidationTasks("relationshipWithGuardians", value);
          }
          setRelationshipWithGuardians(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "relationshipWithGuardians",
            relationshipWithGuardians
          )
        }
        errorMessage={errors.relationshipWithGuardians?.errorMessage}
        hasError={errors.relationshipWithGuardians?.hasError}
        {...getOverrideProps(overrides, "relationshipWithGuardians")}
      ></TextField>
      <TextField
        label="Age when left home"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={ageWhenLeftHome}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome: value,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.ageWhenLeftHome ?? value;
          }
          if (errors.ageWhenLeftHome?.hasError) {
            runValidationTasks("ageWhenLeftHome", value);
          }
          setAgeWhenLeftHome(value);
        }}
        onBlur={() => runValidationTasks("ageWhenLeftHome", ageWhenLeftHome)}
        errorMessage={errors.ageWhenLeftHome?.errorMessage}
        hasError={errors.ageWhenLeftHome?.hasError}
        {...getOverrideProps(overrides, "ageWhenLeftHome")}
      ></TextField>
      <TextField
        label="Marital status"
        isRequired={false}
        isReadOnly={false}
        value={maritalStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus: value,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.maritalStatus ?? value;
          }
          if (errors.maritalStatus?.hasError) {
            runValidationTasks("maritalStatus", value);
          }
          setMaritalStatus(value);
        }}
        onBlur={() => runValidationTasks("maritalStatus", maritalStatus)}
        errorMessage={errors.maritalStatus?.errorMessage}
        hasError={errors.maritalStatus?.hasError}
        {...getOverrideProps(overrides, "maritalStatus")}
      ></TextField>
      <TextField
        label="Partnership duration"
        isRequired={false}
        isReadOnly={false}
        value={partnershipDuration}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration: value,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.partnershipDuration ?? value;
          }
          if (errors.partnershipDuration?.hasError) {
            runValidationTasks("partnershipDuration", value);
          }
          setPartnershipDuration(value);
        }}
        onBlur={() =>
          runValidationTasks("partnershipDuration", partnershipDuration)
        }
        errorMessage={errors.partnershipDuration?.errorMessage}
        hasError={errors.partnershipDuration?.hasError}
        {...getOverrideProps(overrides, "partnershipDuration")}
      ></TextField>
      <TextField
        label="Divorced widowed duration"
        isRequired={false}
        isReadOnly={false}
        value={divorcedWidowedDuration}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration: value,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.divorcedWidowedDuration ?? value;
          }
          if (errors.divorcedWidowedDuration?.hasError) {
            runValidationTasks("divorcedWidowedDuration", value);
          }
          setDivorcedWidowedDuration(value);
        }}
        onBlur={() =>
          runValidationTasks("divorcedWidowedDuration", divorcedWidowedDuration)
        }
        errorMessage={errors.divorcedWidowedDuration?.errorMessage}
        hasError={errors.divorcedWidowedDuration?.hasError}
        {...getOverrideProps(overrides, "divorcedWidowedDuration")}
      ></TextField>
      <SwitchField
        label="In relationship"
        defaultChecked={false}
        isDisabled={false}
        isChecked={inRelationship}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship: value,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.inRelationship ?? value;
          }
          if (errors.inRelationship?.hasError) {
            runValidationTasks("inRelationship", value);
          }
          setInRelationship(value);
        }}
        onBlur={() => runValidationTasks("inRelationship", inRelationship)}
        errorMessage={errors.inRelationship?.errorMessage}
        hasError={errors.inRelationship?.hasError}
        {...getOverrideProps(overrides, "inRelationship")}
      ></SwitchField>
      <TextField
        label="Relationship description"
        isRequired={false}
        isReadOnly={false}
        value={relationshipDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription: value,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.relationshipDescription ?? value;
          }
          if (errors.relationshipDescription?.hasError) {
            runValidationTasks("relationshipDescription", value);
          }
          setRelationshipDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("relationshipDescription", relationshipDescription)
        }
        errorMessage={errors.relationshipDescription?.errorMessage}
        hasError={errors.relationshipDescription?.hasError}
        {...getOverrideProps(overrides, "relationshipDescription")}
      ></TextField>
      <TextField
        label="Sexual orientation"
        isRequired={false}
        isReadOnly={false}
        value={sexualOrientation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation: value,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.sexualOrientation ?? value;
          }
          if (errors.sexualOrientation?.hasError) {
            runValidationTasks("sexualOrientation", value);
          }
          setSexualOrientation(value);
        }}
        onBlur={() =>
          runValidationTasks("sexualOrientation", sexualOrientation)
        }
        errorMessage={errors.sexualOrientation?.errorMessage}
        hasError={errors.sexualOrientation?.hasError}
        {...getOverrideProps(overrides, "sexualOrientation")}
      ></TextField>
      <SwitchField
        label="Have children"
        defaultChecked={false}
        isDisabled={false}
        isChecked={haveChildren}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren: value,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.haveChildren ?? value;
          }
          if (errors.haveChildren?.hasError) {
            runValidationTasks("haveChildren", value);
          }
          setHaveChildren(value);
        }}
        onBlur={() => runValidationTasks("haveChildren", haveChildren)}
        errorMessage={errors.haveChildren?.errorMessage}
        hasError={errors.haveChildren?.hasError}
        {...getOverrideProps(overrides, "haveChildren")}
      ></SwitchField>
      <TextField
        label="Number of children"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={numberOfChildren}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren: value,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.numberOfChildren ?? value;
          }
          if (errors.numberOfChildren?.hasError) {
            runValidationTasks("numberOfChildren", value);
          }
          setNumberOfChildren(value);
        }}
        onBlur={() => runValidationTasks("numberOfChildren", numberOfChildren)}
        errorMessage={errors.numberOfChildren?.errorMessage}
        hasError={errors.numberOfChildren?.hasError}
        {...getOverrideProps(overrides, "numberOfChildren")}
      ></TextField>
      <TextField
        label="Living arrangement"
        isRequired={false}
        isReadOnly={false}
        value={livingArrangement}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement: value,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.livingArrangement ?? value;
          }
          if (errors.livingArrangement?.hasError) {
            runValidationTasks("livingArrangement", value);
          }
          setLivingArrangement(value);
        }}
        onBlur={() =>
          runValidationTasks("livingArrangement", livingArrangement)
        }
        errorMessage={errors.livingArrangement?.errorMessage}
        hasError={errors.livingArrangement?.hasError}
        {...getOverrideProps(overrides, "livingArrangement")}
      ></TextField>
      <SwitchField
        label="Physical abuse"
        defaultChecked={false}
        isDisabled={false}
        isChecked={physicalAbuse}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse: value,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.physicalAbuse ?? value;
          }
          if (errors.physicalAbuse?.hasError) {
            runValidationTasks("physicalAbuse", value);
          }
          setPhysicalAbuse(value);
        }}
        onBlur={() => runValidationTasks("physicalAbuse", physicalAbuse)}
        errorMessage={errors.physicalAbuse?.errorMessage}
        hasError={errors.physicalAbuse?.hasError}
        {...getOverrideProps(overrides, "physicalAbuse")}
      ></SwitchField>
      <SwitchField
        label="Emotional abuse"
        defaultChecked={false}
        isDisabled={false}
        isChecked={emotionalAbuse}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse: value,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.emotionalAbuse ?? value;
          }
          if (errors.emotionalAbuse?.hasError) {
            runValidationTasks("emotionalAbuse", value);
          }
          setEmotionalAbuse(value);
        }}
        onBlur={() => runValidationTasks("emotionalAbuse", emotionalAbuse)}
        errorMessage={errors.emotionalAbuse?.errorMessage}
        hasError={errors.emotionalAbuse?.hasError}
        {...getOverrideProps(overrides, "emotionalAbuse")}
      ></SwitchField>
      <SwitchField
        label="Neglect"
        defaultChecked={false}
        isDisabled={false}
        isChecked={neglect}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect: value,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.neglect ?? value;
          }
          if (errors.neglect?.hasError) {
            runValidationTasks("neglect", value);
          }
          setNeglect(value);
        }}
        onBlur={() => runValidationTasks("neglect", neglect)}
        errorMessage={errors.neglect?.errorMessage}
        hasError={errors.neglect?.hasError}
        {...getOverrideProps(overrides, "neglect")}
      ></SwitchField>
      <SwitchField
        label="Sexual abuse as victim"
        defaultChecked={false}
        isDisabled={false}
        isChecked={sexualAbuseAsVictim}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim: value,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.sexualAbuseAsVictim ?? value;
          }
          if (errors.sexualAbuseAsVictim?.hasError) {
            runValidationTasks("sexualAbuseAsVictim", value);
          }
          setSexualAbuseAsVictim(value);
        }}
        onBlur={() =>
          runValidationTasks("sexualAbuseAsVictim", sexualAbuseAsVictim)
        }
        errorMessage={errors.sexualAbuseAsVictim?.errorMessage}
        hasError={errors.sexualAbuseAsVictim?.hasError}
        {...getOverrideProps(overrides, "sexualAbuseAsVictim")}
      ></SwitchField>
      <SwitchField
        label="Sexual abuse as perpetrator"
        defaultChecked={false}
        isDisabled={false}
        isChecked={sexualAbuseAsPerpetrator}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator: value,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.sexualAbuseAsPerpetrator ?? value;
          }
          if (errors.sexualAbuseAsPerpetrator?.hasError) {
            runValidationTasks("sexualAbuseAsPerpetrator", value);
          }
          setSexualAbuseAsPerpetrator(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "sexualAbuseAsPerpetrator",
            sexualAbuseAsPerpetrator
          )
        }
        errorMessage={errors.sexualAbuseAsPerpetrator?.errorMessage}
        hasError={errors.sexualAbuseAsPerpetrator?.hasError}
        {...getOverrideProps(overrides, "sexualAbuseAsPerpetrator")}
      ></SwitchField>
      <SwitchField
        label="Witnessed abuse"
        defaultChecked={false}
        isDisabled={false}
        isChecked={witnessedAbuse}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse: value,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.witnessedAbuse ?? value;
          }
          if (errors.witnessedAbuse?.hasError) {
            runValidationTasks("witnessedAbuse", value);
          }
          setWitnessedAbuse(value);
        }}
        onBlur={() => runValidationTasks("witnessedAbuse", witnessedAbuse)}
        errorMessage={errors.witnessedAbuse?.errorMessage}
        hasError={errors.witnessedAbuse?.hasError}
        {...getOverrideProps(overrides, "witnessedAbuse")}
      ></SwitchField>
      <TextField
        label="Family deaths"
        isRequired={false}
        isReadOnly={false}
        value={familyDeaths}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths: value,
              distressfulEvents,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.familyDeaths ?? value;
          }
          if (errors.familyDeaths?.hasError) {
            runValidationTasks("familyDeaths", value);
          }
          setFamilyDeaths(value);
        }}
        onBlur={() => runValidationTasks("familyDeaths", familyDeaths)}
        errorMessage={errors.familyDeaths?.errorMessage}
        hasError={errors.familyDeaths?.hasError}
        {...getOverrideProps(overrides, "familyDeaths")}
      ></TextField>
      <SwitchField
        label="Distressful events"
        defaultChecked={false}
        isDisabled={false}
        isChecked={distressfulEvents}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents: value,
              distressfulEventsDetails,
            };
            const result = onChange(modelFields);
            value = result?.distressfulEvents ?? value;
          }
          if (errors.distressfulEvents?.hasError) {
            runValidationTasks("distressfulEvents", value);
          }
          setDistressfulEvents(value);
        }}
        onBlur={() =>
          runValidationTasks("distressfulEvents", distressfulEvents)
        }
        errorMessage={errors.distressfulEvents?.errorMessage}
        hasError={errors.distressfulEvents?.hasError}
        {...getOverrideProps(overrides, "distressfulEvents")}
      ></SwitchField>
      <TextField
        label="Distressful events details"
        isRequired={false}
        isReadOnly={false}
        value={distressfulEventsDetails}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              middleInitial,
              lastName,
              preferredName,
              dob,
              age,
              socialSecurity,
              personCompletingForm,
              personCompletingFormRelationship,
              addressStreet,
              addressCity,
              addressState,
              addressZip,
              addressLivedAt,
              cellPhone,
              email,
              homePhone,
              maritialStatus,
              race,
              highestEduction,
              isVeteran,
              isActiveDuty,
              militaryBranch,
              militaryDeployed,
              militaryDischarge,
              militaryDischargeDate,
              arrested,
              arrestedCharge,
              convicted,
              convictedCharges,
              currentLegalConcerns,
              educationalExperience,
              employmentLevel,
              employmentLength,
              employmentConcerns,
              financialConcerns,
              generalQuestionotherPeopleSaidProblem,
              generalQuestionInterfered,
              generalQuesationArrested,
              generalQuesationCutBack,
              generalQuesationWithdrawal,
              generalQuesationPreoccupied,
              generalQuesationFocused,
              generalQuesationActivities,
              previousTreatment,
              insuranceProvider,
              insurancePolicy,
              insuranceGroup,
              insuranceExpiration,
              ethnicCulturalBackground,
              adopted,
              hometown,
              childhoodLivingArrangement,
              relationshipWithGuardians,
              ageWhenLeftHome,
              maritalStatus,
              partnershipDuration,
              divorcedWidowedDuration,
              inRelationship,
              relationshipDescription,
              sexualOrientation,
              haveChildren,
              numberOfChildren,
              livingArrangement,
              physicalAbuse,
              emotionalAbuse,
              neglect,
              sexualAbuseAsVictim,
              sexualAbuseAsPerpetrator,
              witnessedAbuse,
              familyDeaths,
              distressfulEvents,
              distressfulEventsDetails: value,
            };
            const result = onChange(modelFields);
            value = result?.distressfulEventsDetails ?? value;
          }
          if (errors.distressfulEventsDetails?.hasError) {
            runValidationTasks("distressfulEventsDetails", value);
          }
          setDistressfulEventsDetails(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "distressfulEventsDetails",
            distressfulEventsDetails
          )
        }
        errorMessage={errors.distressfulEventsDetails?.errorMessage}
        hasError={errors.distressfulEventsDetails?.hasError}
        {...getOverrideProps(overrides, "distressfulEventsDetails")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
