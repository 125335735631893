import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer';

import Scrollbar from '../../components/scrollbar';
import Programs from '../../components/Programs';
import { Helmet } from "react-helmet";
function ProgramsPage() {
  return (
    <>
      <Helmet title="Programs" />
      <Navbar />
      <Programs />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default ProgramsPage;
