import React, { Component } from "react";
import "./style.css";

function Amenity(props) {
  return (
    <div className={`card d-sm-none d-md-inline-flex ${props.title}`}>
      <div className="card-body">
        <span className="align-self-start">
          <h5 className="card-title">
          <i className={`fa fa ${props.icon}`} aria-hidden="true"></i>{" "}
            {/* <span className="fa-stack fa-lg">
              <i className="fa fa-square fa-stack-2x"></i>
              <i className={`fa fa ${props.icon} fa-stack-1x`} aria-hidden="true"></i>{" "}
            </span> */}
            {props.title}
          </h5>
        </span>
        <span className="align-self-end">
          <ul className="list-group list-group-flush">{props.children}</ul>
        </span>
      </div>
    </div>
  );
}

class Amenities extends Component {
  constructor(props) {
    super(props);
    if (this.props.displayMansion === undefined) {
      this.displayMansion = false;
    } else if (this.props.displayMansion === "true") {
      this.displayMansion = true;
    }
  }

  render() {
    return (
      //   <div className="row justify-content-around">
      <div className="card-deck d-flex-wrap flex-md-nowrap justify-content-center mb-4">
        <Amenity title="Detox" icon="fa-home">
          <li className="list-group-item">Intervention services available</li>
          <li className="list-group-item">Facilitate entry to detoxes</li>
          <li className="list-group-item">With or without insurance</li>
        </Amenity>
        <Amenity title="Treatment" icon="fa-heart">
          <li className="list-group-item">Find and connect patients with rehabs</li>
          <li className="list-group-item">Inpatient and Outpatient services</li>
          <li className="list-group-item">Get the correct level of care today!</li>
        </Amenity>
        <Amenity title="Recovery" icon="fa-users">
          <li className="list-group-item">Aftercare programs and therapy</li>
          <li className="list-group-item">Local sober-living partnerships</li>
          <li className="list-group-item">Access to nurses, prescribers, pharmacists and more!</li>
        </Amenity>
      </div>
    );
  }
}

export default Amenities;