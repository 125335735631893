import React from 'react';
import Navbar from '../../components/Navbar';
import IntakeForm from '../../components/IntakeForm';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { Helmet } from "react-helmet";
function IntakePage() {
  return (
    <>
      <Helmet title="Client Intake" />
      <Navbar />
      <IntakeForm />
      <Footer />
      <Scrollbar />
    </>
  );
}
export default IntakePage;
