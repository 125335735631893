import React from "react";
import Header from '../header';

export default function Navbar() {
  const [scroll, setScroll] = React.useState(0);

  // const handleScroll = e => {
  const handleScroll = () => {
    // let element = e.target;
    setScroll(document.documentElement.scrollTop);

    // if (element.scrollHeight - element.scrollTop === element.clientHeight) { console.log("We're at the bottom!!") }
    // if (element.scrollHeight > 80) { console.log("We're > 80") }
    // if (scroll > 80) { console.log("Were above 80") }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar animated fadeInDown active" : "fixed-navbar";

  return (
    <div className={className}>
        <Header />
    </div>
  ); 
}