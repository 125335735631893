/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMedication = /* GraphQL */ `
  mutation CreateMedication(
    $input: CreateMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    createMedication(input: $input, condition: $condition) {
      id
      name
      dose
      prescribedFor
      sideEffects
      helpful
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMedication = /* GraphQL */ `
  mutation UpdateMedication(
    $input: UpdateMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    updateMedication(input: $input, condition: $condition) {
      id
      name
      dose
      prescribedFor
      sideEffects
      helpful
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMedication = /* GraphQL */ `
  mutation DeleteMedication(
    $input: DeleteMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    deleteMedication(input: $input, condition: $condition) {
      id
      name
      dose
      prescribedFor
      sideEffects
      helpful
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMedicalInformation = /* GraphQL */ `
  mutation CreateMedicalInformation(
    $input: CreateMedicalInformationInput!
    $condition: ModelMedicalInformationConditionInput
  ) {
    createMedicalInformation(input: $input, condition: $condition) {
      currentMedicalConditions {
        heartDisease
        diabetes
        cancer
        seizures
        tuberculosis
        activityRestrictions
        dietaryRestrictions
        allergies
        infectiousDisease
        otherConditions
        id
        createdAt
        updatedAt
        __typename
      }
      pastHealthProblems
      dateOfLastPhysicianVisit
      nameOfCurrentPhysician
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMedicalInformation = /* GraphQL */ `
  mutation UpdateMedicalInformation(
    $input: UpdateMedicalInformationInput!
    $condition: ModelMedicalInformationConditionInput
  ) {
    updateMedicalInformation(input: $input, condition: $condition) {
      currentMedicalConditions {
        heartDisease
        diabetes
        cancer
        seizures
        tuberculosis
        activityRestrictions
        dietaryRestrictions
        allergies
        infectiousDisease
        otherConditions
        id
        createdAt
        updatedAt
        __typename
      }
      pastHealthProblems
      dateOfLastPhysicianVisit
      nameOfCurrentPhysician
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMedicalInformation = /* GraphQL */ `
  mutation DeleteMedicalInformation(
    $input: DeleteMedicalInformationInput!
    $condition: ModelMedicalInformationConditionInput
  ) {
    deleteMedicalInformation(input: $input, condition: $condition) {
      currentMedicalConditions {
        heartDisease
        diabetes
        cancer
        seizures
        tuberculosis
        activityRestrictions
        dietaryRestrictions
        allergies
        infectiousDisease
        otherConditions
        id
        createdAt
        updatedAt
        __typename
      }
      pastHealthProblems
      dateOfLastPhysicianVisit
      nameOfCurrentPhysician
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubstances = /* GraphQL */ `
  mutation CreateSubstances(
    $input: CreateSubstancesInput!
    $condition: ModelSubstancesConditionInput
  ) {
    createSubstances(input: $input, condition: $condition) {
      name
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubstances = /* GraphQL */ `
  mutation UpdateSubstances(
    $input: UpdateSubstancesInput!
    $condition: ModelSubstancesConditionInput
  ) {
    updateSubstances(input: $input, condition: $condition) {
      name
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubstances = /* GraphQL */ `
  mutation DeleteSubstances(
    $input: DeleteSubstancesInput!
    $condition: ModelSubstancesConditionInput
  ) {
    deleteSubstances(input: $input, condition: $condition) {
      name
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubstanceAbuseHistory = /* GraphQL */ `
  mutation CreateSubstanceAbuseHistory(
    $input: CreateSubstanceAbuseHistoryInput!
    $condition: ModelSubstanceAbuseHistoryConditionInput
  ) {
    createSubstanceAbuseHistory(input: $input, condition: $condition) {
      substanceName {
        name
        id
        createdAt
        updatedAt
        __typename
      }
      substanceCurrent
      substanceFrequency
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubstanceAbuseHistory = /* GraphQL */ `
  mutation UpdateSubstanceAbuseHistory(
    $input: UpdateSubstanceAbuseHistoryInput!
    $condition: ModelSubstanceAbuseHistoryConditionInput
  ) {
    updateSubstanceAbuseHistory(input: $input, condition: $condition) {
      substanceName {
        name
        id
        createdAt
        updatedAt
        __typename
      }
      substanceCurrent
      substanceFrequency
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubstanceAbuseHistory = /* GraphQL */ `
  mutation DeleteSubstanceAbuseHistory(
    $input: DeleteSubstanceAbuseHistoryInput!
    $condition: ModelSubstanceAbuseHistoryConditionInput
  ) {
    deleteSubstanceAbuseHistory(input: $input, condition: $condition) {
      substanceName {
        name
        id
        createdAt
        updatedAt
        __typename
      }
      substanceCurrent
      substanceFrequency
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCurrentMedicalConditions = /* GraphQL */ `
  mutation CreateCurrentMedicalConditions(
    $input: CreateCurrentMedicalConditionsInput!
    $condition: ModelCurrentMedicalConditionsConditionInput
  ) {
    createCurrentMedicalConditions(input: $input, condition: $condition) {
      heartDisease
      diabetes
      cancer
      seizures
      tuberculosis
      activityRestrictions
      dietaryRestrictions
      allergies
      infectiousDisease
      otherConditions
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCurrentMedicalConditions = /* GraphQL */ `
  mutation UpdateCurrentMedicalConditions(
    $input: UpdateCurrentMedicalConditionsInput!
    $condition: ModelCurrentMedicalConditionsConditionInput
  ) {
    updateCurrentMedicalConditions(input: $input, condition: $condition) {
      heartDisease
      diabetes
      cancer
      seizures
      tuberculosis
      activityRestrictions
      dietaryRestrictions
      allergies
      infectiousDisease
      otherConditions
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCurrentMedicalConditions = /* GraphQL */ `
  mutation DeleteCurrentMedicalConditions(
    $input: DeleteCurrentMedicalConditionsInput!
    $condition: ModelCurrentMedicalConditionsConditionInput
  ) {
    deleteCurrentMedicalConditions(input: $input, condition: $condition) {
      heartDisease
      diabetes
      cancer
      seizures
      tuberculosis
      activityRestrictions
      dietaryRestrictions
      allergies
      infectiousDisease
      otherConditions
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClientIntake = /* GraphQL */ `
  mutation CreateClientIntake(
    $input: CreateClientIntakeInput!
    $condition: ModelClientIntakeConditionInput
  ) {
    createClientIntake(input: $input, condition: $condition) {
      id
      firstName
      middleInitial
      lastName
      preferredName
      dob
      age
      socialSecurity
      personCompletingForm
      personCompletingFormRelationship
      addressStreet
      addressCity
      addressState
      addressZip
      addressLivedAt
      cellPhone
      email
      homePhone
      maritialStatus
      race
      highestEduction
      isVeteran
      isActiveDuty
      militaryBranch
      militaryDeployed
      militaryDischarge
      militaryDischargeDate
      arrested
      arrestedCharge
      convicted
      convictedCharges
      currentLegalConcerns
      educationalExperience
      employmentLevel
      employmentLength
      employmentConcerns
      financialConcerns
      SubstanceAbuseHistory {
        substanceCurrent
        substanceFrequency
        id
        createdAt
        updatedAt
        __typename
      }
      medicationHistory {
        id
        name
        dose
        prescribedFor
        sideEffects
        helpful
        createdAt
        updatedAt
        __typename
      }
      medicalInformation {
        pastHealthProblems
        dateOfLastPhysicianVisit
        nameOfCurrentPhysician
        id
        createdAt
        updatedAt
        __typename
      }
      generalQuestionotherPeopleSaidProblem
      generalQuestionInterfered
      generalQuesationArrested
      generalQuesationCutBack
      generalQuesationWithdrawal
      generalQuesationPreoccupied
      generalQuesationFocused
      generalQuesationActivities
      previousTreatment
      insuranceProvider
      insurancePolicy
      insuranceGroup
      insuranceExpiration
      ethnicCulturalBackground
      adopted
      hometown
      childhoodLivingArrangement
      relationshipWithGuardians
      ageWhenLeftHome
      maritalStatus
      partnershipDuration
      divorcedWidowedDuration
      inRelationship
      relationshipDescription
      sexualOrientation
      haveChildren
      numberOfChildren
      livingArrangement
      physicalAbuse
      emotionalAbuse
      neglect
      sexualAbuseAsVictim
      sexualAbuseAsPerpetrator
      witnessedAbuse
      familyDeaths
      distressfulEvents
      distressfulEventsDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientIntake = /* GraphQL */ `
  mutation UpdateClientIntake(
    $input: UpdateClientIntakeInput!
    $condition: ModelClientIntakeConditionInput
  ) {
    updateClientIntake(input: $input, condition: $condition) {
      id
      firstName
      middleInitial
      lastName
      preferredName
      dob
      age
      socialSecurity
      personCompletingForm
      personCompletingFormRelationship
      addressStreet
      addressCity
      addressState
      addressZip
      addressLivedAt
      cellPhone
      email
      homePhone
      maritialStatus
      race
      highestEduction
      isVeteran
      isActiveDuty
      militaryBranch
      militaryDeployed
      militaryDischarge
      militaryDischargeDate
      arrested
      arrestedCharge
      convicted
      convictedCharges
      currentLegalConcerns
      educationalExperience
      employmentLevel
      employmentLength
      employmentConcerns
      financialConcerns
      SubstanceAbuseHistory {
        substanceCurrent
        substanceFrequency
        id
        createdAt
        updatedAt
        __typename
      }
      medicationHistory {
        id
        name
        dose
        prescribedFor
        sideEffects
        helpful
        createdAt
        updatedAt
        __typename
      }
      medicalInformation {
        pastHealthProblems
        dateOfLastPhysicianVisit
        nameOfCurrentPhysician
        id
        createdAt
        updatedAt
        __typename
      }
      generalQuestionotherPeopleSaidProblem
      generalQuestionInterfered
      generalQuesationArrested
      generalQuesationCutBack
      generalQuesationWithdrawal
      generalQuesationPreoccupied
      generalQuesationFocused
      generalQuesationActivities
      previousTreatment
      insuranceProvider
      insurancePolicy
      insuranceGroup
      insuranceExpiration
      ethnicCulturalBackground
      adopted
      hometown
      childhoodLivingArrangement
      relationshipWithGuardians
      ageWhenLeftHome
      maritalStatus
      partnershipDuration
      divorcedWidowedDuration
      inRelationship
      relationshipDescription
      sexualOrientation
      haveChildren
      numberOfChildren
      livingArrangement
      physicalAbuse
      emotionalAbuse
      neglect
      sexualAbuseAsVictim
      sexualAbuseAsPerpetrator
      witnessedAbuse
      familyDeaths
      distressfulEvents
      distressfulEventsDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientIntake = /* GraphQL */ `
  mutation DeleteClientIntake(
    $input: DeleteClientIntakeInput!
    $condition: ModelClientIntakeConditionInput
  ) {
    deleteClientIntake(input: $input, condition: $condition) {
      id
      firstName
      middleInitial
      lastName
      preferredName
      dob
      age
      socialSecurity
      personCompletingForm
      personCompletingFormRelationship
      addressStreet
      addressCity
      addressState
      addressZip
      addressLivedAt
      cellPhone
      email
      homePhone
      maritialStatus
      race
      highestEduction
      isVeteran
      isActiveDuty
      militaryBranch
      militaryDeployed
      militaryDischarge
      militaryDischargeDate
      arrested
      arrestedCharge
      convicted
      convictedCharges
      currentLegalConcerns
      educationalExperience
      employmentLevel
      employmentLength
      employmentConcerns
      financialConcerns
      SubstanceAbuseHistory {
        substanceCurrent
        substanceFrequency
        id
        createdAt
        updatedAt
        __typename
      }
      medicationHistory {
        id
        name
        dose
        prescribedFor
        sideEffects
        helpful
        createdAt
        updatedAt
        __typename
      }
      medicalInformation {
        pastHealthProblems
        dateOfLastPhysicianVisit
        nameOfCurrentPhysician
        id
        createdAt
        updatedAt
        __typename
      }
      generalQuestionotherPeopleSaidProblem
      generalQuestionInterfered
      generalQuesationArrested
      generalQuesationCutBack
      generalQuesationWithdrawal
      generalQuesationPreoccupied
      generalQuesationFocused
      generalQuesationActivities
      previousTreatment
      insuranceProvider
      insurancePolicy
      insuranceGroup
      insuranceExpiration
      ethnicCulturalBackground
      adopted
      hometown
      childhoodLivingArrangement
      relationshipWithGuardians
      ageWhenLeftHome
      maritalStatus
      partnershipDuration
      divorcedWidowedDuration
      inRelationship
      relationshipDescription
      sexualOrientation
      haveChildren
      numberOfChildren
      livingArrangement
      physicalAbuse
      emotionalAbuse
      neglect
      sexualAbuseAsVictim
      sexualAbuseAsPerpetrator
      witnessedAbuse
      familyDeaths
      distressfulEvents
      distressfulEventsDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactRequest = /* GraphQL */ `
  mutation CreateContactRequest(
    $input: CreateContactRequestInput!
    $condition: ModelContactRequestConditionInput
  ) {
    createContactRequest(input: $input, condition: $condition) {
      id
      name
      email
      message
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactRequest = /* GraphQL */ `
  mutation UpdateContactRequest(
    $input: UpdateContactRequestInput!
    $condition: ModelContactRequestConditionInput
  ) {
    updateContactRequest(input: $input, condition: $condition) {
      id
      name
      email
      message
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactRequest = /* GraphQL */ `
  mutation DeleteContactRequest(
    $input: DeleteContactRequestInput!
    $condition: ModelContactRequestConditionInput
  ) {
    deleteContactRequest(input: $input, condition: $condition) {
      id
      name
      email
      message
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
