import React from 'react'
import {Link} from 'react-router-dom'
import ctarr from "../../images/community/ctarr-sm.png";
import conect from "../../images/affiliates/conect.png";
import onestep from "../../images/affiliates/onestep.png";
import samhsa from "../../images/affiliates/samhsa.png";
import narr from "../../images/affiliates/narr-lg.png";

import './style.css'

const Affiliates = () => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return (
        <div className="case-area section-padding text-center">
            <div className="container">
                <div className="col-lg-8 offset-lg-2">
                    <div className="section-title section-title2 text-center">
                        <div className="thumb-text">
                            <span>Friends!</span>
                        </div>
                        <h2>Partnerships and Affiliates</h2>
                        <span><Link onClick={ClickHandler} to="/partners">Learn More</Link></span>
                    </div>
                </div>
                </div>
                <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-8 col-md-2 col-lg-2 py-3">
                        <img draggable="false" src={onestep} alt="" />
                    </div>
                    <div className="col-8 col-md-2 col-lg-2 py-3">
                        <img draggable="false" src={narr} alt="" />
                    </div>
                    <div className="col-8 col-md-2 col-lg-2 py-3">
                        <img draggable="false" src={samhsa} alt="" />
                    </div>
                    <div className="col-8 col-md-2 col-lg-2 py-3">
                        <img draggable="false" src={ctarr} alt="" />
                    </div>
                    <div className="col-8 col-md-2 col-lg-2 py-3">
                        <img draggable="false" src={conect} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Affiliates;