import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

import jobPlacement from '../../images/vector/job-placement.png'
import soberHouse from '../../images/vector/sober-house.png'
import community from '../../images/vector/community.png'

const MissionIcons = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="mission-container">
            <div className="row text-center">
                
                {/* <div className="col-lg-6 offset-lg-3">
                    <div className="section-title text-center">
                        <h2>Our Recovery Program</h2>
                    </div>
                </div> */}
                <div className="col-lg-4">
                    <img src={jobPlacement} alt="" className="col col-6"/>
                    <h2>Support</h2>
                    <p></p>
                </div>
                <div className="col-lg-4">
                    <img src={soberHouse} alt="" className="col col-6" />
                    <h2>Recover</h2>
                    <p></p>
                </div>
                <div className="col-lg-4">
                    <img src={community} alt="" className="col col-6" />
                    <h2>Grow</h2>
                    <p></p>
                </div>

                <div className="col-lg-6 offset-lg-3">
                    <div className="section-title text-center">
                        <Link onClick={ClickHandler} to="/programs" className="theme-btn">Learn More!<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissionIcons;