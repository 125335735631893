import React from 'react';
import { Helmet } from "react-helmet";
import env from "react-dotenv";

// TODO: Remove Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AllRoute from '../router';
import './App.scss';


// import { Amplify } from 'aws-amplify';
// import { generateClient } from 'aws-amplify/api';


// import { AmplifyProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

// import { generateClient } from 'aws-amplify/api';
// import awsmobile from '../../aws-exports';
// console.log(awsmobile);
// Amplify.configure(awsmobile);

// import config from './amplifyconfiguration.json';
// Amplify.configure(config);

// Amplify.configure({
//     API: {
//       GraphQL: {
//         endpoint: process.env.REACT_APP_AWS_ENDPOINT,
//         region: 'us-east-1',
//         // Set the default auth mode to "apiKey" and provide the API key value
//         defaultAuthMode: 'apiKey',
//         apiKey: process.env.REACT_APP_API_KEY
//       }
//     }
//   });

// const client = generateClient();


// https://www.jondjones.com/frontend/react/npm-packages-for-react-developers/how-to-add-page-metadata-using-react-helmet/
// Good Example:
// https://github.com/gabroun/malikgabroun/blob/master/src/components/Seo.js
function App() {
  return (
    <>
      <Helmet 
      title="Home"
      titleTemplate="%s | Endependence"
      base={{"href": env.PUBLIC_URL}}>
          <meta name="description" content="Endependence offers recovery services to addicts and their family seeking help." />
          <meta name="keywords" content="Endependence, sober living, ct sober living, sober houses CT, recovery, treatment center, CT treatment center" />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="English" />
      </Helmet>
      <div className="App" id="scrool">
        <AllRoute />
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
